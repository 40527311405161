import React, { useEffect, useRef, useState } from 'react'
import hostlink from '../Hostlink/hostlink';

import { Link } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify';
import axios from 'axios';
import Modal from 'react-modal';
const PurchaseGR = () => {
    const roomCodeRef = useRef(null);
    const usershopid = localStorage.getItem('MYPCKARTUSER') ? JSON.parse(localStorage.getItem('MYPCKARTUSER'))[0].id : '0';
    const scrollRef = useRef(null);
    const today = new Date();
    const [itemdetailGridRefresh, setitemdetailGridRefresh] = useState(true)
    const [editDeleteAPi, seteditDeleteAPi] = useState('');
    const [query, setQuery] = useState('');
    const [data, setData] = useState([]);
    const [AccData, setAccData] = useState([]);

    const [srvdata, setsrvData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [selectedItem, setSelectedItem] = useState(null);
    const [selectedIndex, setSelectedIndex] = useState(-1);
    const [listVisible, setListVisible] = useState(false);
    const [isEditModenew, setisEditModenew] = useState(false);
    const [isEditModenewshopvno, setisEditModenewshopvno] = useState(0);
    const inputRef = useRef(null);

    const fetchData = async () => {
        const response = await fetch(`${hostlink}/PurchaseMasFindSortNo/${usershopid}`);
        const jsonData = await response.json();
        console.log(jsonData);

        const responseStock = await fetch(`${hostlink}/PurchaseMasSTKGodwn/${usershopid}`);
        const jsonDataStock = await responseStock.json();
        console.log(jsonDataStock);

        // Loop through jsonData and add stkqtyroll and stkqty from jsonDataStock where sortno matches
        jsonData.forEach((item) => {
            const stockItem = jsonDataStock.find(stock => stock.purchaseMas.sortno === item.sortno);
            if (stockItem) {
                item.stkqtyroll = stockItem.stkqtyroll;
                item.stkqty = stockItem.stkqty;
            }
        });

        console.log(jsonData);
        setData(jsonData);
    };
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [messagedata, setMessagedata] = useState({
        deleteid: "",
        deletemsg: ""
    })
    const { deleteid, deletemsg } = messagedata;
    const handleOpenModal = (e, flname) => {
        setMessagedata({ ...messagedata, deleteid: e, deletemsg: flname })
        setIsModalOpen(true);
    };
    const handleCloseModal = () => { setIsModalOpen(false); };
    const handleNoClick = () => { console.log('User clicked No'); handleCloseModal(); };
    const printdata = async (e) => {
        try {
            const response = await axios.get(`${hostlink}/getchehinroomservicePrint/${usershopid}/${e}`);
            const printWindow = window.open('', '_blank');
            if (response && response.data && response.data.length > 0) {
                const printContent = generatePrintContent(response.data);
                const data = `1,${hostlink}/getchehinroomservicePrint/${usershopid}/${e},${printContent}`;
                console.log(data)
                // Check if ReactNativeWebView is available
                if (window.ReactNativeWebView) {
                    window.ReactNativeWebView.postMessage(data);
                    return false;
                }
                printWindow.document.write(printContent);
                printWindow.document.close();
            } else {
                console.error("Empty or invalid response received.");
                return;
            } printWindow.print();
        } catch (error) {
            console.error("Error fetching or printing data:", error);
        }
    };

    const generatePrintContent = (data) => {
        console.log(data)
        const tableInfo = data.length > 0 && data[0] !== null ? `<span style="text-align: end; width:50%;"><p1 style="text-align: end; width:50%;">Room Name : ${data[0][0]}</p1></span>` : '';
        return `<style>*{margin: 0;padding: 0;box-sizing: border-box;}div{width: 3in;}hr{width: 3in;}</style><div style="font-family: 'Bahnschrift Condensed'; width: 3in;"><p1 style="display: block; margin: 0 auto;text-align: center;"> ROOM SERVICE </p1>
        <img style="display: block; margin: 0 auto;" src="./logo${usershopid}.png" alt="" />
        <h1 style="text-align: center;">${localStorage.getItem('MYPCKARTUSER') ? JSON.parse(localStorage.getItem('MYPCKARTUSER'))[0].cname : '0'}</h1><hr style="borderTop: 1px solid black; width: 3in;" /><span style="display:flex;"><p1 style="text-align: start; width:50%;">Ord No : ${data.length > 0 ? data[0][13] : ''}</p1>${tableInfo}</p1></span><span style="display:flex; width: 3in;"><p1 style="text-align: start; width:50%;">Ord Date : ${data[0][7] ? new Date(data[0][7]).toLocaleDateString('en-GB', { day: '2-digit', month: '2-digit', year: '2-digit' }).replace(/\//g, '/') : "No data"}</p1><p1 style="text-align: end; width:50%;">Ord Time : ${data[0][8] ? new Date(`2022-01-01T${data[0][8]}`).toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit', hour12: true }) : "-"}</p1></span><table style="border-Top: 1px solid black; width: 3in;"><tr style="border-bottom: 1px solid black;"><td style="width: 10%;border-bottom: 1px solid black;">SN</td><td style="width: 80%;border-bottom: 1px solid black;">Item Name</td><td style="width: 10%;border-bottom: 1px solid black; text-align: end;">Qty</td></tr>
          ${data.map((x, index) => `<tr style="width: 100%"><td style="width: 10%">${index + 1}</td><td style="width: 80%">${x[1]}</td><td style="width: 10%; text-align: end;">${x[2].toFixed(2)}</td></tr>`).join('')}</table><hr style="borderTop: 1px solid black; width: 3in;" /><span style="display:flex;"><p1 style="text-align: end; width:100%;">Total Qty : ${Number(data.reduce((total, resp) => total + parseFloat(resp[2]), 0)).toFixed(2)}</p1></span><hr style="borderTop: 1px solid black; width: 3in;" />      
        </div>`;
    };
    useEffect(() => {
        fetchData();
    }, []);

    const cmdEdit = (vno) => {
        setisEditModenew(true);
        setisEditModenewshopvno(vno);
        console.log(`${hostlink}/PurchaseMasEdit/${usershopid}/${vno}`);
        let x = document.getElementById("viewform")
        x.style.display = "None"
        let y = document.getElementById("newform")
        y.style.display = "block"

        axios.get(`${hostlink}/PurchaseMasEdit/${usershopid}/${vno}`)
            .then((resp) => {
                const modifiedData = resp.data.map(item => ({
                    ...item,
                    mtr: item.mtr * -1,
                    roll: item.roll * -1
                  }));
              
                  // Set the modified data
                // setitemdetailGrid(resp.data)
                setitemdetailGrid(modifiedData);
                setitemdetail({
                    ...itemdetail,
                    billno: resp.data[0].billno,
                    billdate: resp.data[0].billdate,
                    grdate: resp.data[0].grdate,
                    acccode: resp.data[0].acccode,
                    accname: resp.data[0].accname,
                });

            })
    }
    const cmdDelete = (vno) => {

        axios.delete(`${hostlink}/PurchaseMasdelete/${usershopid}/${vno}`)
            .then((resp) => {
                toast.success("Delete Sucessfull")
                setTimeout(() => {
                    setitemdetailGridRefresh(true)
                    handleCloseModal();
                }, 1000);
            })
            .catch((resp) => {
                console.log("Something Else")
            })
    }

    const handleInputChange = (event) => {
        const inputValue = event.target.value;
        setQuery(inputValue);
        filterData(inputValue);
        setListVisible(true);
        setSelectedIndex(-1);
    };

    const filterData = (query) => {
        if (!query) {
            setFilteredData([]);
            return;
        }

        const filtered = data.filter(item => {
            return item.sortno && item.sortno.toLowerCase().includes(query.toLowerCase());
        }).slice(0, 5); // Limit to first 5 records after filtering

        setFilteredData(filtered);
    };
    const saveroomservice = async (isprint) => {
        document.getElementById("cmdsave").disabled = true

        if (billno == "") {
            toast.error("Please Enter Bill No...")
            document.getElementById("billno").focus();
            document.getElementById("cmdsave").disabled = false
            return false
        }

        if (billdate == "") {
            toast.error("Please Enter Bill Date...")
            document.getElementById("billdate").focus();
            document.getElementById("cmdsave").disabled = false
            return false
        }

        if (itemdetailGrid.length === 0) {
            toast.error("Nothing to Save. Add Item...")
            document.getElementById("cmdsave").disabled = false
            return false
        }



        for (let i = 0; i < itemdetailGrid.length; i++) {
            itemdetailGrid[i].id = '';
            itemdetailGrid[i].billno = billno;
            itemdetailGrid[i].billdate = billdate;
            itemdetailGrid[i].grdate = grdate;
            itemdetailGrid[i].shopid = usershopid;
            itemdetailGrid[i].acccode = acccode;
            itemdetailGrid[i].accname = accname;
            itemdetailGrid[i].mtr = itemdetailGrid[i].mtr*-1;
            itemdetailGrid[i].roll = itemdetailGrid[i].roll*-1;
            itemdetailGrid[i].entrytype = 10;
            itemdetailGrid[i].ittotal = itemdetailGrid[i].mtr * itemdetailGrid[i].rate;
            itemdetailGrid[i].totqty = itemdetailGrid.reduce((total, resp) => total + parseFloat(resp.mtr), 0);
            itemdetailGrid[i].billamt = itemdetailGrid.reduce((total, resp) => total + parseFloat(Number(resp.rate) * Number(resp.mtr)), 0);
        }

        if (isEditModenew == true) {

            // const duplicateCheckResp = await axios.get(`${hostlink}/PurchaseMasCheckDuplicateEdit/${usershopid}/${acccode}/${billno}/${isEditModenewshopvno}`);
            // if (duplicateCheckResp.data.length !== 0) {
            //     toast.error("Bill No already exists...");
            //     document.getElementById("billno").focus();
                
            //     document.getElementById("cmdsave").disabled = false;
                
            //     return; // Early return to stop execution if duplicate exists
            // }    

            await axios.post(`${hostlink}/PurchaseMasEditsave/${usershopid}/${isEditModenewshopvno}`, itemdetailGrid)
                .then((resp) => { toast.success("Save Sucess.."); setInterval(() => { window.location.assign("/PurchaseGR") }, 1000); })
                .catch((resp) => { console.log("Data Not Save..") })
        } else {
            // const duplicateCheckResp = await axios.get(`${hostlink}/PurchaseMasCheckDuplicate/${usershopid}/${acccode}/${billno}`);

            // if (duplicateCheckResp.data.length !== 0) {
            //     toast.error("Bill No already exists...");
            //     document.getElementById("billno").focus();
                
            //     document.getElementById("cmdsave").disabled = false;
            //     return; // Early return to stop execution if duplicate exists
            // }

            await axios.post(`${hostlink}/PurchaseMas`, itemdetailGrid)
                .then((resp) => { toast.success("Save Sucess.."); setInterval(() => { window.location.assign("/PurchaseGR") }, 1000); })
                .catch((resp) => { console.log("Data Not Save..") })
        }
    }
    const handleSelectItem = (item, index) => {
        setSelectedItem(item);
        setQuery(item.itname);
        setListVisible(false);
        setSelectedIndex(index);
    };
    const [itemdetail, setitemdetail] = useState({ rawcode: 0, rawname: "", barcode: "", sortno: "", billno: "",grdate: today.toISOString().split('T')[0], billdate: today.toISOString().split('T')[0], mtr: "", roll: "", qty: 1, rate: 0, discperc: 0, taxable: 0, gst: 0, gstamt: 0, ittotal: 0, totqty: 0, totgst: 0, totordamt: 0, rcode: "", roomnoview: "" });
    const { rawcode, rawname, barcode, sortno, qty, billno, grdate, billdate, mtr, roll, rate, acccode, accname, discperc, taxable, gst, gstamt, ittotal } = itemdetail;
    const [itemdetailGrid, setitemdetailGrid] = useState([]);
    useEffect(() => {
        if (scrollRef.current) {
            scrollRef.current.scrollTop = scrollRef.current.scrollHeight;
        }
    }, [itemdetailGrid]);

    useEffect(() => {


        axios.get(`${hostlink}/${usershopid}/accmas`)
            .then((Response) => {
                setAccData(Response.data)
            })
            .catch((Response) => {
                console.log("Data Not Fatched")
            })

        axios.get(`${hostlink}/PurchaseMas/10/${usershopid}/${fromdate}/${todate}`)
            .then((Response) => {
                setsrvData(Response.data)
                setitemdetailGridRefresh(false)
            })
            .catch((Response) => {
                console.log("Data Not Fatched")
            })
    }, [itemdetailGridRefresh]);
    const handleItemClick = (index) => {
        setSelectedIndex(index);
        handleSelectItem(filteredData[index], index);
        setQuery(filteredData[index].sortno);
        setitemdetail({
            ...itemdetail,

            sortno: filteredData[index].sortno,
            qty: 1,
            mtr: filteredData[index].stkqty,
            grdate: filteredData[index].billdate,
            billno: filteredData[index].billno,
            acccode: filteredData[index].acccode,
            accname: filteredData[index].accname,
            roll: filteredData[index].stkqtyroll,
            rate: filteredData[index].rate,
            discperc: filteredData[index].discperc,
            gst: filteredData[index].gst,
        });
        document.getElementById("mtr").focus();
    };
    const handleKeyDown = (event) => {
        if (document.getElementById("inputname1111").value == "") {
            return false
        }
        if (event.key === 'ArrowDown') {
            event.preventDefault();
            setSelectedIndex(prevIndex => (prevIndex < filteredData.length - 1 ? prevIndex + 1 : prevIndex));
        } else if (event.key === 'ArrowUp') {
            event.preventDefault();
            setSelectedIndex(prevIndex => (prevIndex > 0 ? prevIndex - 1 : prevIndex));
        } else if (event.key === 'Enter' && selectedIndex !== -1) {
            handleSelectItem(filteredData[selectedIndex], selectedIndex);
            setQuery(filteredData[selectedIndex].sortno);
            setitemdetail({
                ...itemdetail,
                sortno: filteredData[selectedIndex].sortno,
                grdate: filteredData[selectedIndex].billdate,
                billno: filteredData[selectedIndex].billno,
                acccode: filteredData[selectedIndex].acccode,
                accname: filteredData[selectedIndex].accname,
                mtr: filteredData[selectedIndex].stkqty,
                roll: filteredData[selectedIndex].stkqtyroll,
                rate: filteredData[selectedIndex].rate,
                gst: filteredData[selectedIndex].gst,
            });
            document.getElementById("mtr").focus();
        }
    };

    const handleFocus = (e) => {
        // setTimeout(() => {
        //     e.target.select(); // Select the input value when it receives focus
        // }, 0);
    };
    const handleKeyDownnext = (event) => {
        if (event.keyCode === 39) {
            event.preventDefault(); // Prevent cursor from moving to the end

            // Ensure the event target is an input element and supports selection range
            if (event.target instanceof HTMLInputElement && ['text', 'password', 'search', 'tel', 'url'].includes(event.target.type)) {
                event.target.setSelectionRange(0, event.target.value.length); // Select the input value
            }
        }

        if (event.keyCode === 13 || event.keyCode === 9) { // Check if the Enter key or Tab key is pressed
            event.preventDefault(); // Prevent default Tab behavior
            const interactiveElements = document.querySelectorAll('button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])');
            const currentIndex = Array.from(interactiveElements).indexOf(event.target); // Get the index of the current interactive element
            if (currentIndex < interactiveElements.length - 1) {
                interactiveElements[currentIndex + 1].focus(); // Focus on the next interactive element
            } else if (event.keyCode === 9) {
                interactiveElements[0].focus(); // Cycle back to the first element if Tab is pressed on the last element
            }
        }
    };


    const [frmdata, setfrmdata] = useState({
        fromdate: today.toISOString().split('T')[0],
        todate: today.toISOString().split('T')[0],
        status: 3
    });
    const { fromdate, todate, status } = frmdata;
    const handleKeyDownFindItem = (event) => {

        const existingItem = data.find(item => item.barcode === barcode);
        if (existingItem) {
            console.log("Item found:", existingItem);

            setQuery(existingItem.itname)
            setitemdetail({
                ...itemdetail,
                rawcode: existingItem.id,
                rawname: existingItem.itname,
                barcode: existingItem.barcode,
                discperc: existingItem.discperc,
                qty: 1,
                rate: existingItem.restrate,
                gst: existingItem.gst,
            });
        } else {
            setitemdetail({
                ...itemdetail,
                id: 0,
            });
            return false
        }


        if (event.keyCode === 13 || event.keyCode === 9) {
            const interactiveElements = document.querySelectorAll('button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])');
            const currentIndex = Array.from(interactiveElements).indexOf(event.target);

            if (currentIndex < interactiveElements.length - 1) {
                interactiveElements[currentIndex + 1].focus();
                console.log("1")

            } else if (event.keyCode === 9) {
                interactiveElements[0].focus();
                console.log("2")
            }
        }
    };
    const [guestdata, setguestdata] = useState({ c_Gname: "", c_GMob: "", c_GAdd: "", c_Grcode: "", c_Grcodename: "", c_GCHID: "" });
    const { c_Gname, c_GMob, c_GAdd, c_Grcode, c_Grcodename, c_GCHID } = guestdata;
    const onChangevalueRoom = (e, f) => {
        const existingItem = Room.find(item => item[0] === f);
        if (existingItem) {
            console.log("find")

            const updatedItDetails = Room.map(roomdata => {
                if (roomdata[0] === f) {
                    setguestdata({ ...guestdata, c_GCHID: roomdata[1], c_Grcodename: f, c_Gname: roomdata[2], c_GMob: roomdata[3], c_GAdd: roomdata[4], c_Grcode: roomdata[5] })
                    toast.success("Conform Guest Detail..");
                    document.getElementById("inputname1111").focus();
                }
            });

        } else {
            setguestdata({ ...guestdata, c_Gname: "", c_GMob: "", c_GAdd: "", c_Grcode: "" })
            toast.error("Select Room");
        }
    }

    const onChangevalue = (e) => {
        const { name, value } = e.target;
        const sanitizedValue = value.replace(/['"!`@#$%^&*+{}|;:,<>?=]/g, '');
        setitemdetail({ ...itemdetail, [name]: sanitizedValue });
    }
    const onChangevalueloaddata = (e) => {
        const { name, value } = e.target;
        const sanitizedValue = value.replace(/['"!`@#$%^&*+{}|;:,.<>?=]/g, '');
        setfrmdata({ ...frmdata, [name]: sanitizedValue });
        setitemdetailGridRefresh(true)
    }
    const [isVisible, setIsVisible] = useState(false);

    const [Room, setRoom] = useState([])
    const [items, setItems] = useState([]);
    const handleDoubleClick = (itemId) => {
        const selectedItem = items.find(item => item.id === itemId);
        if (selectedItem) {
            alert(`Double-clicked on: ${selectedItem.itname}`);
        }
    };
    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    };
    // Show button when user scrolls down 400px
    useEffect(() => {
        if (scrollRef.current) {
            scrollRef.current.scrollTop = scrollRef.current.scrollHeight;
        }


        const toggleVisibility = () => {
            if (window.pageYOffset > 100) {
                setIsVisible(true);
            } else {
                setIsVisible(false);
            }
        };

        window.addEventListener('scroll', toggleVisibility);

        return () => {
            window.removeEventListener('scroll', toggleVisibility);
        };
    }, []);

    const handleDeleteItem = async (id) => {
        const updatedItDetails = itemdetailGrid.filter(item => item.sortno !== id);
        toast.success("Item deleted");
        setitemdetailGrid(updatedItDetails);
        document.getElementById("inputname1111").focus();
    };
    const handleEditItem = async (id) => {
        const existingItem = itemdetailGrid.find(item => item.sortno === id);
        if (existingItem) {
            setQuery(existingItem.sortno)
            setitemdetail({
                ...itemdetail,
                sortno: existingItem.sortno,
                mtr: existingItem.mtr,
                rate: existingItem.rate,
                roll: existingItem.roll,
            });
            document.getElementById("inputname1111").focus();
        }
    };
    const onChangevalueFab = (fabcode, fabname) => {
        setitemdetail({
            ...itemdetail,
            acccode: fabcode,
            accname: fabname
        });
    };
    const handleAddItem = () => {

        if (sortno == "") { toast.error("Please Enter Sort No..."); document.getElementById("inputname1111").focus(); return false }
        if (mtr == "") { toast.error("Please Enter Mtr No..."); document.getElementById("mtr").focus(); return false }
        if (rate == "") { toast.error("Please Enter rate No..."); document.getElementById("rate").focus(); return false }
        if (roll == "") { toast.error("Please Enter Roll No..."); document.getElementById("roll").focus(); return false }

         // Find the corresponding stock for the entered sortno
         const stockItem = data.find(stock => stock.sortno === sortno); // Assuming `data` holds the stock information
        
         if (!stockItem) {
             toast.error("Stock not available for this sort number");
             return false;
         }
     
         // Calculate total mtr and roll already used in existing entries with the same sortno
         let existingTotalMtr = 0;
         let existingTotalRoll = 0;
     
         itemdetailGrid.forEach(item => {
             if (item.sortno === sortno) {
                 existingTotalMtr += Math.abs(item.mtr);  // Using absolute value because it's negative in your case
                 existingTotalRoll += Math.abs(item.roll);
             }
         });
     
         // Check if the new mtr and roll would exceed available stock
         const totalMtr = existingTotalMtr + Number(mtr);
         const totalRoll = existingTotalRoll + Number(roll);
     
         if (totalMtr > stockItem.stkqty) {
             toast.error(`Insufficient stock for Mtr. Available: ${stockItem.stkqty - existingTotalMtr}`);
            //  return false;
         }
     
         if (totalRoll > stockItem.stkqtyroll) {
             toast.error(`Insufficient stock for Roll. Available: ${stockItem.stkqtyroll - existingTotalRoll}`);
             //return false;
         }
     

        const existingItem = itemdetailGrid.find(item => item.sortno === sortno);
        if (existingItem) {
            const updatedItDetails = itemdetailGrid.map(item => {
                if (item.sortno === sortno) {
                    return { ...item, mtr: Number(mtr), rate: Number(rate), roll: Number(roll) };
                }
                return item;
            });
            setitemdetailGrid(updatedItDetails);
            toast.success(`Sort no : ${sortno}, Updated...`);
            setQuery('')
            setitemdetail({
                ...itemdetail,
                sortno: "",
                mtr: "",
                rate: "",
                roll: "",
            });
            document.getElementById("inputname1111").focus();
        } else {
            setitemdetailGrid([...itemdetailGrid, {
                sortno: sortno,
                mtr: mtr,
                rate: rate,
                roll: roll,
            }])
            toast.success("Item added..");
            setQuery('')
            setitemdetail({
                ...itemdetail,
                sortno: "",
                mtr: "",
                rate: "",
                roll: "",
            });
            document.getElementById("inputname1111").focus();
        }
    };
    const cmdNew = () => {
        setisEditModenew(false);
        setisEditModenewshopvno(0);
        seteditDeleteAPi('')
        let x = document.getElementById("viewform")
        x.style.display = "None"
        let y = document.getElementById("newform")
        y.style.display = "block"
    }
    const searchData = (e) => {
        const searchValue = document.getElementById("searchroom").value.trim();
        console.log(searchValue)
        if (searchValue === "") {
            axios.get(`${hostlink}/PurchaseMas/10/${usershopid}/${fromdate}/${todate}`)
                .then((Response) => {
                    setsrvData(Response.data)
                    setitemdetailGridRefresh(false)
                })
                .catch(() => { })
        } else {
            axios.get(`${hostlink}/PurchaseMasSearch/10/${usershopid}/${fromdate}/${todate}/${searchValue}`)
                .then((Response) => {
                    setsrvData(Response.data)
                    setitemdetailGridRefresh(false)
                })
                .catch(() => { })
        }
    }
    return (


        <div>
            <div id='viewform'>
                <div className='container p-2'>
                    <div className='row p-1'>
                        <div className='col-md-2 p-1'>
                            <h5 className='font-monospace'><i class="fa-solid fa-arrow-rotate-left"></i> GR</h5>
                        </div>
                        <div className='col-md-2 rounded' style={{ display: "flex", alignItems: "center", background: "rgb(245,245,246)", height: "40px" }}>
                            <i class="fa-solid fa-magnifying-glass " ></i> &nbsp;&nbsp; <input type="text" class="" name="searchroom" onChange={(e) => { searchData(e) }} id="searchroom" placeholder="Search Bill No" style={{ background: "rgb(245,245,246)", outline: "none", border: "0", width: "100%" }} /> <br />
                        </div>

                        <div className='col-md-6 align-self-end' style={{ display: "flex", flexDirection: "row", justifyContent: "center" }}>
                            <span className="f-size-on-mobile align-self-end" style={{ verticalAlign: 'middle', height: '100%', }}>
                                <label htmlFor="todate" className="form-label text-center">From : &nbsp;</label>
                            </span>
                            <span> <input type="date" id="fromdate" name="fromdate" onChange={(e) => { onChangevalueloaddata(e) }} value={fromdate} className="form-control" /></span>


                            <span className="f-size-on-mobile align-self-end">
                                <label htmlFor="todate" className="form-label text-center">&nbsp; To : &nbsp;</label>
                            </span>
                            <span>
                                <input type="date" id="todate" name="todate" onChange={(e) => { onChangevalueloaddata(e) }} value={todate} className="form-control" />
                            </span>
                        </div>


                        <div className='col-md-2 text-end'>
                            <button id='cmdnew' onClick={cmdNew} className='btn btn-outline-primary'> <i class="fa-solid fa-plus"></i> &nbsp;Create New </button>
                        </div>
                        <div style={{ maxHeight: "80vh", overflow: "auto" }} className='col-12 '>
                            <table className="table table-hover table-striped">
                                <thead>
                                    <tr>
                                        <th scope="col" className="f-size-on-mobile"><span className='hide-on-mobile'>GR Date</span> <span className='show-on-mobile'>Details</span></th>
                                        <th scope="col" className="hide-on-mobile">Bill Date</th>
                                        <th scope="col" className="hide-on-mobile">Bill No</th>
                                        <th scope="col" className="hide-on-mobile">Party</th>
                                        <th scope="col" className="hide-on-mobile">Tot Qty</th>
                                        <th scope="col" className="hide-on-mobile">Bill Amount</th>

                                        <th scope="col" className="f-size-on-mobile text-center">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {srvdata.map((res, x) => (
                                        <tr style={{ verticalAlign: 'middle', height: '100%' }}>
                                            <td className="f-size-on-mobile" style={{ wordWrap: "break-word" }}> <span className='show-on-mobile'><b>GR Date :</b> </span> {res.billdate ? new Date(res.billdate).toLocaleDateString('en-GB', { day: '2-digit', month: '2-digit', year: '2-digit' }).replace(/\//g, '/') : "No data"} <br />
                                                <div className="detail-on-mobile text-start align-self-start" style={{ fontSize: "12px", color: "blue", alignItems: "start", wordWrap: "break-word" }}>
                                                    {res[2] !== '' && (<>Bill Date : {res.grdate ? new Date(res.grdate).toLocaleDateString('en-GB', { day: '2-digit', month: '2-digit', year: '2-digit' }).replace(/\//g, '/') : "No data"} <br /></>)}
                                                    {res[2] !== '' && (<>Party : {res.accname} <br /></>)}
                                                    {res[2] !== '' && (<>Bill No : {res.billno} <br /></>)}
                                                    {res[10] !== 0 && (<>Bill Amount : {res.billamt*-1} <br /></>)} <span style={{ color: "blue" }}> </span>
                                                    <span className='text-bg-danger'>{res[0] !== '' && (<>Total Mtr : {res.totqty*-1} <br /></>)}</span>
                                                </div>
                                            </td>
                                            <td className="hide-on-mobile" style={{ wordWrap: "break-word" }}>{res.grdate ? new Date(res.grdate).toLocaleDateString('en-GB', { day: '2-digit', month: '2-digit', year: '2-digit' }).replace(/\//g, '/') : "No data"}</td>
                                            <td className="hide-on-mobile" style={{ wordWrap: "break-word" }}>{res.billno}</td>
                                            <td className="hide-on-mobile" style={{ wordWrap: "break-word" }}>{res.accname}</td>
                                            <td className="hide-on-mobile" style={{ wordWrap: "break-word" }}>{res.totqty*-1}</td>
                                            <td className="hide-on-mobile" style={{ wordWrap: "break-word" }}>{res.billamt*-1}</td>
                                            <td className='text-center' style={{ verticalAlign: 'middle', height: '100%' }}>
                                                <Link to={"/PurchaseGR"} onClick={(e) => { cmdEdit(res.shopvno) }} className='btn btn-sm btn-outline-dark'><i class="fa-solid fa-pen-to-square"></i><span className="hide-on-mobile" > Edit</span></Link> &nbsp; <span className="detail-on-mobile"><br /> <br /></span>
                                                {/* <Link className='btn btn-sm btn-outline-primary' onClick={(e) => { printdata(res[11]) }}><i class="fa-solid fa-print"></i> <span className="hide-on-mobile">Print</span></Link> &nbsp; */}
                                                <Link className='btn btn-sm btn-danger' onClick={(e) => { handleOpenModal(res.shopvno, res.billno) }} > <i class="fa-solid fa-trash"></i> <span className="hide-on-mobile"> Delete</span></Link> &nbsp;
                                                <Modal isOpen={isModalOpen} onRequestClose={handleCloseModal} contentLabel="Delete Confirmation" className="modal-content" overlayClassName="modal-overlay">
                                                    <div><p>Are you sure you want to delete this <br /> Bill No : {deletemsg} ?</p><div className="button-container"><button className="yes-button" onClick={(e) => { cmdDelete(deleteid) }}>Yes</button> <button className="no-button" onClick={handleNoClick}>No</button></div></div>
                                                </Modal>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>

            {/* New Service */}
            <div id='newform' style={{ display: "none" }}>
                <div className='container-fluid py-1 '>
                    <div className='row py-1 shadow' style={{ backgroundImage: 'linear-gradient(to right, #1Ea5E0, #1Ea5E0, #1Ea5E0)', color: 'white' }}>
                        <div className="col-md-12 align-self-center"><h4> &nbsp;<i class="fa-solid fa-arrow-rotate-left"></i> GR </h4></div>
                    </div>
                    <p></p>
                    <div className="row">
                        <div className="col-3 col-md-1 ">Party :</div>
                        <div className="col-12 col-md-2">
                            <select
                                id="fabname"
                                onChange={(e) => {
                                    const selectedIndex = e.target.selectedIndex;
                                    const acccode = e.target.value;
                                    const accname = e.target.options[selectedIndex].text; // Get the selected text
                                    onChangevalueFab(acccode, accname);  // Pass both fabcode and fabname
                                }}
                                value={itemdetail.acccode || 0}  // Bind value to state (itemdetail.fabcode)
                                name="fabname"
                                className="form-select"
                            >
                                <option key={0} value={0}>
                                    Choose...
                                </option>
                                {AccData.map((x) => (
                                    <option key={x.id} value={x.id}>
                                        {x.accname}
                                    </option>
                                ))}
                            </select>


                        </div>


                        <div className="col-3 col-md-1 ">Bill No:</div>
                        <div className="col-12 col-md-2">
                            <input type="text" value={billno} name='billno' onKeyDown={handleKeyDownnext} onFocus={handleFocus} onChange={(e) => { onChangevalue(e) }} className="form-control" id="billno" />
                        </div>
                        <div className="col-3 col-md-1 ">Bill date :</div>
                        <div className="col-12 col-md-2">
                        <input type="date" value={grdate} name='grdate' onKeyDown={handleKeyDownnext} onFocus={handleFocus} onChange={(e) => { onChangevalue(e) }} className="form-control" id="grdate" />
                        </div>
                        <div className="col-3 col-md-1 ">GR date :</div>
                        <div className="col-12 col-md-2">
                            
                            <input type="date" value={billdate} name='billdate' onKeyDown={handleKeyDownnext} onFocus={handleFocus} onChange={(e) => { onChangevalue(e) }} className="form-control" id="billdate" />
                        </div>

                    </div>

                    <p></p>
                    <div className="row">
                        <div className="col-md-12">

                            <div className="row py-1">
                                <div className="col-8 col-md-4">
                                    <label className='font-monospace' class="form-label">Sort No </label>
                                    <input type="text" placeholder="Search for Sort No " class="form-control" value={query} id="inputname1111" onFocus={handleFocus} onChange={handleInputChange} onKeyDown={handleKeyDown} ref={inputRef} /> <br />
                                    {listVisible && filteredData.length > 0 && (
                                        <ul style={{}}>
                                            {filteredData.map((item, index) => (
                                                <li
                                                    key={item.sortno}
                                                    onClick={() => handleSelectItem(item, index)}
                                                    onMouseDown={() => handleItemClick(index)}

                                                    className={index === selectedIndex ? 'selected' : ''}
                                                >
                                                    {item.sortno} <span style={{color:"red"}}>(Mtr : {item.stkqty}, Roll: {item.stkqtyroll})</span>
                                                </li>
                                            ))}
                                        </ul>
                                    )}
                                </div>
                                <div className="col-4 col-md-2">
                                    <label className='font-monospace' class="form-label">Mtr</label>
                                    <input type="number" value={mtr} name='mtr' onKeyDown={handleKeyDownnext} onFocus={handleFocus} onChange={(e) => { onChangevalue(e) }} class="form-control" id="mtr" /> <br />
                                </div>
                                <div className="col-4 col-md-2">
                                    <label className='font-monospace' class="form-label">Rate</label>
                                    <input type="number" value={rate} name='rate' onKeyDown={handleKeyDownnext} onFocus={handleFocus} onChange={(e) => { onChangevalue(e) }} class="form-control" id="rate" /> <br />
                                </div>
                                <div className="col-4 col-md-2">
                                    <label className='font-monospace' class="form-label">Roll</label>
                                    <input type="number" value={roll} name='roll' onKeyDown={handleKeyDownnext} onFocus={handleFocus} onChange={(e) => { onChangevalue(e) }} class="form-control" id="roll" /> <br />
                                </div>
                                <div id='addroomNew' className="col-4 col-md-2 text-center align-content-start">
                                    <label className='font-monospace ' style={{ color: "white" }} class="form-label">-</label> <br />
                                    <button style={{ backgroundImage: 'linear-gradient(to right, #ff9999, #ff9999, #ff9999)', color: 'black', fontSize: "12px", width: "90%", textAlign: "center", justifyContent: "center" }} type="submit" onClick={() => { handleAddItem() }} class="btn btn-info"><i class="fa-solid fa-circle-plus"></i></button>
                                </div>

                            </div>

                            <div className='show-on-mobile row   container' style={{ color: 'black' }}>
                                {itemdetailGrid.map((resp, index) => (
                                    <div key={index} className='row text-center shadow rounded align-self-center p-1 m-0 bg-light border border-1'>
                                        <p style={{ "color": "blue", borderBottom: "1px solid black" }}>Sort No : {resp.sortno}</p>
                                        <div className='col-9 text-start' style={{ fontSize: "13px", color: "black" }}>
                                            <table style={{ "width": "100%" }}>
                                                <tr><td>Mtr :</td><td>: {resp.mtr}</td><td>Rate :</td><td>: {resp.rate}</td></tr>
                                                <tr><td>Roll :</td><td>: {resp.roll}</td><td>Total :</td><td>: {(resp.mtr * resp.rate)}</td></tr>
                                            </table>
                                        </div>
                                        <div className='col-3 align-self-center'>
                                            <button type="submit" onClick={(e) => { handleEditItem(resp.sortno) }} className="btn btn-sm btn-outline-dark"> <i className="fa-solid fa-pen-to-square"></i></button> &nbsp;
                                            <button type="submit" onClick={(e) => { handleDeleteItem(resp.sortno) }} className="btn btn-sm btn-outline-danger"> <i className="fa-solid fa-trash"></i></button>
                                        </div>
                                    </div>
                                ))}
                            </div>

                            <div className='hide-on-mobile text-left align-self-center row bg-info p-1 m-0 shadow rounded border border-1' style={{ backgroundImage: 'linear-gradient(to right, #4DD6F4, #ff9999, #4DD6F4)', color: 'black', padding: '20px' }}>
                                <div className='row text-left align-self-center p-1 m-0 '>
                                    <div className='col-md-3'>Sort No</div>
                                    <div className='col-md-1'>Mtr</div>
                                    <div className='col-md-2 text-end'>Rate</div>
                                    <div className='col-md-2 text-end'>Roll</div>
                                    <div className='col-md-2 text-end'>Total</div>
                                    <div className='col-md-2 text-end'>Action</div>
                                </div>
                            </div>
                            <div className='hide-on-mobile' style={{ maxHeight: "365px", overflow: "auto" }} ref={scrollRef}>
                                {itemdetailGrid.map((resp, index) => (
                                    <div key={index} className='row text-left p-1 m-0 bg-light border border-1'>
                                        <div className='col-md-3 align-self-center'>{resp.sortno}</div>
                                        <div className='col-md-1 align-self-center'>{resp.mtr}</div>
                                        <div className='col-md-2 align-self-center text-end'>{resp.rate}</div>
                                        <div className='col-md-2 align-self-center text-end'>{resp.roll}</div>
                                        <div className='col-md-2 align-self-center text-end'>{Number(resp.mtr) * Number(resp.rate)}</div>
                                        <div className='col-md-2 align-self-center text-end'>
                                            <button type="submit" onClick={(e) => { handleEditItem(resp.sortno) }} className="btn btn-sm btn-outline-dark"> <i className="fa-solid fa-pen-to-square"></i></button> &nbsp;
                                            <button type="submit" onClick={(e) => { handleDeleteItem(resp.sortno) }} className="btn btn-sm btn-outline-danger"> <i className="fa-solid fa-trash"></i></button>
                                        </div>
                                    </div>
                                ))}
                                <div className=' bg-dark text-bg-dark row text-left p-1 m-0 bg-light border border-1'>
                                    <div className='col-md-2 align-self-center'></div>
                                    <div className='col-md-1 align-self-center text-start'><b>Total :</b></div>
                                    <div className='col-md-1 align-self-center  text-start'><b>{itemdetailGrid.reduce((total, resp) => total + parseFloat(Number(resp.mtr)), 0)}</b></div>
                                    <div className='col-md-2 align-self-center  text-end'></div>
                                    <div className='col-md-1 align-self-center  text-end'></div>
                                    <div className='col-md-1 align-self-center  text-end'></div>
                                    <div className='col-md-1 align-self-center  text-end'></div>
                                    <div className='col-md-1 align-self-center  text-end'><b>{itemdetailGrid.reduce((total, resp) => total + parseFloat(Number(resp.rate) * Number(resp.mtr)), 0)}</b></div>
                                    <div className='col-md-1 align-self-center'>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3 ">
                        </div>
                    </div>
                </div>
                <br /><br /><br /><br />
                <div className="parent-container">
                    {/* Fixed element at the bottom */}
                    <div className="fixed-bottom" style={{ position: 'fixed', bottom: 0, width: '100%', backgroundColor: '#fff', borderTop: '1px solid black' }}>

                        <div className="container-fluid" >

                            <div className="row">
                                <div className="col-md-9 f-size-on-mobile text-end p-1" style={{ borderRight: "1px solid black" }}>
                                    <p className='hide-on-mobile m-2'></p>
                                    <button id='cmdsave' onClick={() => { saveroomservice(0) }} class="btn btn-primary">Save</button>  &nbsp;
                                    <a href='/PurchaseGR' class="btn btn-outline-primary">Cancel</a>
                                </div>
                                {/* Summary */}
                                <div className="col-md-3 f-size-on-mobile ">
                                    <div className='rounded'>
                                        <div className="row p-1 m-1 f-size-on-mobile">
                                            <div className="col-6 text-end size-on-mobile" style={{ fontFamily: 'arial', fontSize: '18px', fontWeight: 'bold', backgroundColor: "black", color: "white" }}> Total :</div> <div style={{ fontFamily: 'arial', backgroundColor: "black", color: "white", fontSize: '16px', fontWeight: 'bold' }} className="col-6 text-end"> {itemdetailGrid.reduce((total, resp) => total + parseFloat(Number(resp.rate) * Number(resp.mtr)), 0)} </div>
                                        </div>
                                    </div>

                                </div>
                            </div>

                        </div>
                    </div>
                </div>


            </div>
            <ToastContainer autoClose={1000}></ToastContainer>
        </div>
    )
}

export default PurchaseGR

