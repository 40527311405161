import React, { useEffect, useRef, useState } from 'react';
import './Test.css'; // Ensure correct path
import axios from 'axios';
import hostlink from '../Hostlink/hostlink';
import { ToastContainer, toast } from 'react-toastify';
const RoomList = ({ rooms }) => {
    const [scrollPercent, setScrollPercent] = useState(0);
    const listRef = useRef(null);

    useEffect(() => {
        const handleScroll = () => {
            if (listRef.current) {
                const { scrollTop, scrollHeight, clientHeight } = listRef.current;
                const scrolled = (scrollTop / (scrollHeight - clientHeight)) * 100;
                setScrollPercent(scrolled);
            }
        };

        const listElement = listRef.current;
        listElement.addEventListener('scroll', handleScroll);

        return () => {
            listElement.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <div className="list-container" >
            <div className="scroll-progress" style={{ width: `${scrollPercent}%` }}></div>
            <h5 className="text-center">Rooms ({rooms.length})</h5>
            <div className="list-scroll" ref={listRef}>
                {rooms.map((room, index) => (
                    <div
                        className={`list-item ${room.stname === 'Booked' ? 'booked-bg' : 'available-bg'}`}
                        key={index}
                    >
                        <div className={`status-indicator ${room.stname === 'Booked' ? 'bg-danger' : 'bg-success'}`} ></div>
                        <span className="float-end" style={{ minWidth: '5px', display: 'inline-block', textAlign: 'right' }}>{room.name}</span>
                        <span className="float-end" style={{ minWidth: '75px', display: 'inline-block', textAlign: 'center' }}>{room.rtname}</span>
                        <span className="float-end" style={{ minWidth: '75px', display: 'inline-block', textAlign: 'right' }}>₹{room.rent}</span>
                        {/* <span className="float-end">
                            {room.stname === 'Booked' ? 'Booked' : 'Available'}
                        </span> */}
                        <span><button className='btn btn-sm btn-dark'>{room.stname === 'Booked' ? 'Check-Out' : 'Check-In'} </button></span>
                    </div>
                ))}
            </div>
        </div>
    );
};

const TableListOrder = ({ orders }) => {
    console.log(orders)
    const [scrollPercent, setScrollPercent] = useState(0);
    const listRef = useRef(null);

    useEffect(() => {
        const handleScroll = () => {
            if (listRef.current) {
                const { scrollTop, scrollHeight, clientHeight } = listRef.current;
                const scrolled = (scrollTop / (scrollHeight - clientHeight)) * 100;
                setScrollPercent(scrolled);
            }
        };

        const listElement = listRef.current;
        listElement.addEventListener('scroll', handleScroll);

        return () => {
            listElement.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <div className="list-container">
    <h5 className="text-center">Running Orders ({orders.length})</h5>
    <div className="list-scroll" ref={listRef}>
        <div className="row">
            {orders
                .map((room, index) => (
                    <div key={index} className={`col-3 mb-3`}>
                        <button
                            className={`btn shadow rounded  ${room[2] === 2 ? 'btn-light' : 'btn-info'}`}
                            style={{ height: '60px',width:"100%" }}
                        >
                        {room[2] === 2 ? <i class="fa-solid fa-truck"></i> : <i class="fa-solid fa-gift"></i>} <br /> {room[0]}&nbsp;
                        </button>
                    </div>
                ))}
        </div>
    </div>
    <div className="scroll-progress" style={{ width: `${scrollPercent}%` }}></div>
</div>   
    );
};

const TableList = ({ tables }) => {
    const [scrollPercent, setScrollPercent] = useState(0);
    const listRef = useRef(null);

    useEffect(() => {
        const handleScroll = () => {
            if (listRef.current) {
                const { scrollTop, scrollHeight, clientHeight } = listRef.current;
                const scrolled = (scrollTop / (scrollHeight - clientHeight)) * 100;
                setScrollPercent(scrolled);
            }
        };

        const listElement = listRef.current;
        listElement.addEventListener('scroll', handleScroll);

        return () => {
            listElement.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <div className="list-container">
    <h5 className="text-center">Running Tables ({tables.filter(room => room.status === 0).length})</h5>
    <div className="list-scroll" ref={listRef}>
        <div className="row">
            {tables
                .filter(room => room.status === 1)
                .map((room, index) => (
                    <div key={index} className="col-3 mb-3">
                        <button
                            className='btn shadow rounded btn-danger'
                            style={{ height: '50px',width:"100%" }}
                        >
                            {room.tname}
                        </button>
                    </div>
                ))}
        </div>
    </div>
    <div className="scroll-progress" style={{ width: `${scrollPercent}%` }}></div>
</div>   
    );
};
const TableListRunning = ({ tables }) => {
    const [scrollPercent, setScrollPercent] = useState(0);
    const listRef = useRef(null);

    useEffect(() => {
        const handleScroll = () => {
            if (listRef.current) {
                const { scrollTop, scrollHeight, clientHeight } = listRef.current;
                const scrolled = (scrollTop / (scrollHeight - clientHeight)) * 100;
                setScrollPercent(scrolled);
            }
        };

        const listElement = listRef.current;
        listElement.addEventListener('scroll', handleScroll);

        return () => {
            listElement.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <div className="list-container">
            <h5 className="text-center">Running Tables ({tables.filter(room => room.status == 1).length})</h5>
            <div className="list-scroll" ref={listRef}>
                {tables
                    .filter(room => room.status == 1) // Filter for only booked rooms
                    .map((room, index) => (
                        <div
                            className={`list-item ${room.status == '1' ? 'booked-bg' : 'available-bg'}`}
                            key={index}
                        >
                            <div className={`status-indicator ${room.status === 1 ? 'bg-danger' : 'bg-success'}`}></div>
                            <span>{room.tname}</span>
                            <span
                                className="float-end"
                                style={{ minWidth: '75px', display: 'inline-block', textAlign: 'right' }}
                            >
                                Available
                            </span>
                        </div>
                    ))}
            </div>
            <div className="scroll-progress" style={{ width: `${scrollPercent}%` }}></div>
        </div>
    );
};

const Banner = ({ totalRooms, runningRooms, availableRooms, totalTables, runningTables, availableTables }) => {
    const today = new Date();
    const runningRoomPercentage = totalRooms > 0 ? (runningRooms / totalRooms) * 100 : 0;
    const runningTablePercentage = totalTables > 0 ? (runningTables / totalTables) * 100 : 0;
    const [RoomsDashboard, setRoomsDashboard] = useState([]);
    const usershopid = localStorage.getItem('MYPCKARTUSER') ? JSON.parse(localStorage.getItem('MYPCKARTUSER'))[0].id : '0';
    useEffect(() => {
        // hoteldate
        // console.log(`${hostlink}/api/executeQuery?sqlQuery=select (select chdate from checkinroomdetails where shopid = ${usershopid} order by id desc limit 1) as lastcheckin,(select bldate from billmas where shopid = ${usershopid} order by id desc limit 1) as lastcheckOut,(select blamt from billmas where shopid = ${usershopid} order by id desc limit 1) as lastBillAmt,(select guestname from billmas where shopid = ${usershopid} order by id desc limit 1) as lastGuestName,(select shopvno from kotmas where shopid = ${usershopid} order by id desc limit 1) as lastKot,(select shopvno from salemas where shopid = ${usershopid} order by id desc limit 1) as lastBillrest,(select totblamt from salemas where shopid = ${usershopid} order by id desc limit 1) as lastBillamtrest,(select custname from salemas where shopid = ${usershopid} order by id desc limit 1) as dlvcust,(SELECT SUM(totblamt) FROM (SELECT DISTINCT shopvno, totblamt FROM salemas WHERE shopid = ${usershopid} AND bldate = '${today.toISOString().split('T')[0]}')) as todaysale from shopmas where id =${usershopid}`)        
        axios.get(`${hostlink}/api/executeQuery?sqlQuery=select (select chdate from checkinroomdetails where shopid = ${usershopid} order by id desc limit 1) as lastcheckin,(select bldate from billmas where shopid = ${usershopid} order by id desc limit 1) as lastcheckOut,(select blamt from billmas where shopid = ${usershopid} order by id desc limit 1) as lastBillAmt,(select guestname from billmas where shopid = ${usershopid} order by id desc limit 1) as lastGuestName,(select shopvno from kotmas where shopid = ${usershopid} order by id desc limit 1) as lastKot,(select shopvno from salemas where shopid = ${usershopid} order by id desc limit 1) as lastBillrest,(select totblamt from salemas where shopid = ${usershopid} order by id desc limit 1) as lastBillamtrest,(select custname from salemas where shopid = ${usershopid} order by id desc limit 1) as dlvcust,(SELECT SUM(totblamt) FROM (SELECT DISTINCT shopvno, totblamt FROM salemas WHERE shopid = ${usershopid} AND bldate = '${today.toISOString().split('T')[0]}') as todaysale) as todaysale from shopmas where id =${usershopid}`)
            .then((response) => {
                setRoomsDashboard(response);
            })
            .catch((error) => {
                console.log("Data Not Found...", error);
            });
    }, [])

    return (
        <div className="container-fluid">
            <div className="row">
                <div className="col-md-6 mb-3 rounded shadow" style={{ background: "linear-gradient(rgb(0,0,0,0.5),rgb(0,0,0,0.5)) , url('/b3.jpeg')", backgroundSize: "100% 100%" }}>
                    <div  >
                        <div className="d-flex flex-column align-items-center">
                            <table className='text-end' style={{ "fontFamily": "Bahnschrift Condensed" }}>
                                <tbody>
                                    <tr><td>Total Rooms : </td><td> &nbsp;{totalRooms}</td></tr>
                                </tbody>
                            </table>
                            <table className='text-end' style={{ "fontFamily": "Bahnschrift Condensed" }}>
                                <tbody>
                                    <tr><td>Booked Rooms : </td><td> &nbsp;{runningRooms}</td><td>&nbsp;&nbsp;&nbsp;Available Rooms : </td><td> &nbsp;{availableRooms}</td></tr>
                                </tbody>
                            </table>

                            <table className='text-end' style={{ "fontFamily": "Bahnschrift Condensed" }}>
                                <tbody>
                                    <tr><td>Last Check-in Date : </td><td> {RoomsDashboard.data && RoomsDashboard.data[0] && RoomsDashboard.data[0][0] ? new Date(RoomsDashboard.data[0][0]).toLocaleDateString('en-GB', { day: '2-digit', month: '2-digit', year: '2-digit' }).replace(/\//g, '/') : 'NO DATA'}</td></tr>
                                    <tr><td>Last Check-out Date : </td><td> {RoomsDashboard.data && RoomsDashboard.data[0] && RoomsDashboard.data[0][1] ? new Date(RoomsDashboard.data[0][1]).toLocaleDateString('en-GB', { day: '2-digit', month: '2-digit', year: '2-digit' }).replace(/\//g, '/') : 'NO DATA'} </td></tr>
                                    <tr><td>Last Bill-amount : </td><td> &nbsp; {RoomsDashboard.data && RoomsDashboard.data[0] && RoomsDashboard.data[0][2] ? Number(RoomsDashboard.data[0][2]).toFixed(2) : 0}</td></tr>
                                    <tr><td>Guest Name : </td><td> &nbsp;{RoomsDashboard.data && RoomsDashboard.data[0] && RoomsDashboard.data[0][3] ? RoomsDashboard.data[0][3].toUpperCase() : 'NO DATA'}</td></tr>
                                </tbody>
                            </table>
                            <div className="progress w-75 mt-2">
                                <div className="progress-bar bg-warning" role="progressbar" style={{ width: `${runningRoomPercentage}%` }} aria-valuenow={runningRoomPercentage} aria-valuemin="0" aria-valuemax="100">
                                    {runningRoomPercentage.toFixed(0)}%
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-6 mb-3" style={{ fontSize: "14px" }}>
                    <div className="banner shadow" style={{ background: "linear-gradient(rgb(0,0,0,0.5),rgb(0,0,0,0.5)) , url('/b3.png')", backgroundSize: "100% 100%" }}>
                        <div className="d-flex flex-column align-items-center">
                            <table className='text-end' style={{ "fontFamily": "Bahnschrift Condensed" }}>
                                <tbody>
                                    <tr><td>Total Table : </td><td> &nbsp;{totalTables}</td></tr>
                                </tbody>
                            </table>
                            <table className='text-end' style={{ "fontFamily": "Bahnschrift Condensed" }}>
                                <tbody>
                                    <tr><td>Running Table : </td><td> &nbsp;{runningTables}</td><td>&nbsp;&nbsp;&nbsp; Available Table : </td><td> &nbsp;{availableTables}</td></tr>
                                </tbody>
                            </table>

                            <table className='text-end' style={{ "fontFamily": "Bahnschrift Condensed" }}>
                                <tbody>

                                    <tr><td>Last Order No : </td><td> &nbsp;{RoomsDashboard.data && RoomsDashboard.data[0] && RoomsDashboard.data[0][4] ? Number(RoomsDashboard.data[0][4]) : 0}</td></tr>
                                    <tr><td>Last Bill-No : </td><td> &nbsp; {RoomsDashboard.data && RoomsDashboard.data[0] && RoomsDashboard.data[0][5] ? Number(RoomsDashboard.data[0][5]) : 0}</td></tr>
                                    <tr><td>Last Bill-amount : </td><td> &nbsp;{RoomsDashboard.data && RoomsDashboard.data[0] && RoomsDashboard.data[0][6] ? Number(RoomsDashboard.data[0][6]) : 0}</td></tr>
                                    <tr><td>Customer Name : </td><td> &nbsp;{RoomsDashboard.data && RoomsDashboard.data[0] && RoomsDashboard.data[0][7] ? RoomsDashboard.data[0][7].toUpperCase() : 'NO DATA'}</td></tr>
                                    <tr><td>Today Sale : </td><td> &nbsp;{RoomsDashboard.data && RoomsDashboard.data[0] && RoomsDashboard.data[0][8] ? Number(RoomsDashboard.data[0][8]) : 0}</td></tr>
                                </tbody>
                            </table>
                            <div className="progress w-75 mt-2">
                                <div className="progress-bar bg-primary" role="progressbar" style={{ width: `${runningTablePercentage}%` }} aria-valuenow={runningTablePercentage} aria-valuemin="0" aria-valuemax="100">
                                    {runningTablePercentage.toFixed(0)}%
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
const Home = () => {
    const today = new Date();
    const [selectedOption, setSelectedOption] = useState('option1');
    const usershopid = localStorage.getItem('MYPCKARTUSER') ? JSON.parse(localStorage.getItem('MYPCKARTUSER'))[0].id : '0';
    const handleChange = (event) => {
        setSelectedOption(event.target.value);
    };
    const [totalRooms, setTotalRooms] = useState(0);

    const [runningRooms, setRunningRooms] = useState(0);
    const [availableRooms, setAvailableRooms] = useState(0);
    const [totalTables, setTotalTables] = useState(0);
    const [runningTables, setRunningTables] = useState(0);
    const [availableTables, setAvailableTables] = useState(0);
    const [rooms, setRooms] = useState([]);
    const [tables, setTables] = useState([]);
    const [orders, setOrders] = useState([]);
    const [filterRoom, setfilterRoom] = useState('Available');

    const handleChangeRoomstatus = (e) => {
        axios.get(`${hostlink}/${usershopid}/roomstatus/0/0/${e}`)
            .then((response) => {
                setRooms(response.data);
            })
            .catch((error) => {
                console.log("Room Data Not Found...", error);
            });
        // setfilterRoom(e)
    };

    const handleChangetatus = (e) => {
        axios.get(`${hostlink}/${usershopid}/roomstatus/0/0/3`)
            .then((response) => {
                const rooms = response.data;
                const total = rooms.length;
                const running = rooms.filter(room => room.stname === 'Booked').length;
                const available = total - running;

                setTotalRooms(total);
                setRunningRooms(running);
                setAvailableRooms(available);
                setRooms(rooms);
            })
            .catch((error) => {
                console.log("Room Data Not Found...", error);
            });
    };


async function checkUserPassword() {
    const storedUser = localStorage.getItem('MYPCKARTUSER') ? JSON.parse(localStorage.getItem('MYPCKARTUSER'))[0] : null;
    
    const usershopid = storedUser ? storedUser.id : '0';
    const usershoppass = storedUser ? storedUser.password : '';

    try {
        
        const nameResponse = await axios.get(`${hostlink}/shopmas/${usershopid}`);
        console.log(nameResponse.length)
        if (nameResponse.data.password === usershoppass) {
            // Password matches, proceed with further actions
            return true;
        } else {
            // Password mismatch, remove user and show error toast
            localStorage.removeItem('MYPCKARTUSER');
            console.log("Something else...")
            return false;
        }
    } catch (error) {
        console.error("Error during API request:", error);
        toast.error("Error fetching user data.");
        return false;
    }
}

    useEffect(() => {


        checkUserPassword()
        // Fetch room data
        axios.get(`${hostlink}/${usershopid}/roomstatus/0/0/3`)
            .then((response) => {
                const rooms = response.data;
                const total = rooms.length;
                const running = rooms.filter(room => room.stname === 'Booked').length;
                const available = total - running;

                setTotalRooms(total);
                setRunningRooms(running);
                setAvailableRooms(available);
                handleChangeRoomstatus(0)

            })
            .catch((error) => {
                console.log("Room Data Not Found...", error);
            });

                    // Orders
        // console.log(`${hostlink}/api/executeQuery?sqlQuery=select shopvno,blno,kottype from kotmas where shopid = ${usershopid} and kottype in (2,3) and kotdate = '${today.toISOString().split('T')[0]}' group by shopvno,blno,kottype`)                    
        axios.get(`${hostlink}/api/executeQuery?sqlQuery=select shopvno,blno,kottype from kotmas where shopid = ${usershopid} and kottype in (2,3) and kotdate = '${today.toISOString().split('T')[0]}' group by shopvno,blno,kottype`)
        .then((response) => {
            setOrders(response.data);
            console.log(response.data)
        })
        .catch((error) => {
            console.log("Table Data Not Found...", error);
        });
        // Fetch table data
        axios.get(`${hostlink}/${usershopid}/table`)
            .then((response) => {
                const tables = response.data;
                const total = tables.length;
                const running = tables.filter(table => table.status === 1).length;
                const available = total - running;

                setTotalTables(total);
                setRunningTables(running);
                setAvailableTables(available);
                setTables(tables);
            })
            .catch((error) => {
                console.log("Table Data Not Found...", error);
            });

    }, []);

    return (
        <div className=""
            style={{
                height: "100vh", color: "#fff", margin: "0", padding: "0", overflowX: "hidden", background: "linear-gradient(rgb(0,0,0,0.2),rgb(0,0,0,0.1)) , url('/b1.jpeg')", height: "100vh", backgroundRepeat: "no-repeat", width: "100%", backgroundAttachment: "fixed", backgroundSize: "100% 100%"
            }}

        >
            <div className='container-fluid'>
                <div className='row'>
                    {/* Rooms */}


                    {/* <div className='col-md-6 font-Bahnschrift Condensed'>
                        <h5>Rooms</h5>
                        <div className="col-12 rounded bg-black">
                            <div className="row rounded bg-black" style={{ display: 'flex', border: "1px solid white" }}>
                                <div className="col-md-3" style={{ marginBlockEnd: "5px", marginTop: "5px" }}> <button type="button" onClick={() => { handleChangeRoomstatus(0) }} class="btn btn-primary w-100">Available : {availableRooms}</button></div>
                                <div className="col-md-3" style={{ marginBlockEnd: "5px", marginTop: "5px" }}> <button type="button" onClick={() => { handleChangeRoomstatus(1) }} class="btn btn-danger w-100">Booked : {runningRooms}</button></div>
                                <div className="col-md-3" style={{ marginBlockEnd: "5px", marginTop: "5px" }}> <button type="button" onClick={() => { handleChangeRoomstatus(2) }} class="btn btn-warning w-100">Cleaning</button></div>
                                <div className="col-md-3" style={{ marginBlockEnd: "5px", marginTop: "5px" }}> <button type="button" onClick={() => { handleChangeRoomstatus(4) }} class="btn btn-secondary w-100">Reserved</button></div>
                            </div>
                        </div>
                        <br />
                        <div className="col-md-12">
                            <RoomList rooms={rooms} />
                        </div>
                        <Banner totalRooms={totalRooms} runningRooms={runningRooms} availableRooms={availableRooms} totalTables={totalTables} runningTables={runningTables} availableTables={availableTables} />
                    </div> */}
                    {/* Running Order  */}
                    {/* <div className='col-md-6  font-Bahnschrift Condensed'>
                        <h5><b><u></u></b> <br />
                        <label> <input type="radio" value="option1" checked={selectedOption === 'option1'} onChange={handleChange} />  All </label> &nbsp;
                        <label> <input type="radio" value="option2" checked={selectedOption === 'option2'} onChange={handleChange} />   <i class="fa-solid fa-table"></i> Dine-in </label>&nbsp;
                        <label> <input type="radio" value="option3" checked={selectedOption === 'option3'} onChange={handleChange} />   <i class="fa-solid fa-truck"></i> Delivery </label>&nbsp;
                        <label> <input type="radio" value="option4" checked={selectedOption === 'option4'} onChange={handleChange} />   <i class="fa-solid fa-gift"></i> Takeaway </label>&nbsp;
                        </h5>
                        <div className="col-md-12">
                        <TableList tables={tables} />
                        </div>
                        <div className="col-md-12">
                        <TableListOrder orders={orders} />
                        </div>
                    </div> */}

                </div>
            </div>

            {/* <div className="row">
                <div className="col-md-3">
                    <RoomList rooms={rooms} />
                </div>
                <div className="col-md-3">
                    <RoomListBooked rooms={rooms} />
                </div>
                <div className="col-md-3">
                    <TableList tables={tables} />
                </div>
                <div className="col-md-3">
                    <TableListRunning tables={tables} />
                </div>
            </div> */}
            <br /><br />
            <ToastContainer autoClose={1000}></ToastContainer>
            <footer className="fixed-bottom"> <marquee className='bg-dark text-light p-1' behavior="alternate" direction="left"> We offer customized software solutions that meet the requirements of your business. We have multiple software products with us with all required modules. (For more details, contact us at: +91-9205802778, Email: sujeetraj995@gmail.com) </marquee> </footer>
        </div>
    );
};

export default Home;
