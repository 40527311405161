import React, { useEffect, useRef, useState } from 'react';
import axios from 'axios';
import hostlink from '../Hostlink/hostlink';
import * as XLSX from 'xlsx';
import { PDFDownloadLink, PDFViewer, Document, Page, Text, View, StyleSheet } from '@react-pdf/renderer';

const Rpt_WashReport = () => {
    const usershopid = localStorage.getItem('MYPCKARTUSER') ? JSON.parse(localStorage.getItem('MYPCKARTUSER'))[0].id : '0'
    const [RoomType, setRoomType] = useState([]);
    const [Room, setRoom] = useState([]);
    const [deptdata, setdeptdata] = useState([])
    const [data, setData] = useState([]);
    const today = new Date();
    const [FebData, setFebData] = useState([])
    const [WData, setWData] = useState([])
    const [frmdata, setfrmdata] = useState({
        fromdate: today.toISOString().split('T')[0],
        todate: today.toISOString().split('T')[0],
        deptcode: 0,
        status: 1
    });
    const [totalSums, setTotalSums] = useState({});
    const [itemdetail, setitemdetail] = useState({ Washername: "", Washercode: 0, fabname: "", fabcode: 0 });
    const { fabcode, fabname, Washercode, Washername, } = itemdetail;

    const onChangevalueFab = (fabcode, fabname) => {
        setitemdetail({
            ...itemdetail,
            fabcode: fabcode,
            fabname: fabname
        });
    };

    const onChangevalueWasher = (Washercode, Washername) => {
        setitemdetail({
            ...itemdetail,
            Washercode: Washercode,
            Washername: Washername
        });
    };
    const { fromdate, todate, status, deptcode } = frmdata;
    const tableRef = useRef(null);
    const onChangevalue = (e) => {
        const { name, value } = e.target;
        const sanitizedValue = value.replace(/['"!`@#$%^&*+{}|;:,.<>?=]/g, '');
        setfrmdata({ ...frmdata, [name]: sanitizedValue });
    };
    const loaddata = async () => {
        try {
            
            const billResponse = await axios.get(`${hostlink}/fatchWashReport/${usershopid}/${fromdate}/${todate}/${Washercode}/${fabcode}`);
            const billData = billResponse.data;
            const filteredData = billData.filter(res => {
                const totalQuantity = Number(res.stkInfab) + Number(res.inQty);
                if (status == 0) {
                    return true; 
                } else if (status == 1) {
                    return totalQuantity !== 0; 
                } else if (status == 2) {
                    return res.inQty < 0; 
                }
                return false; 
            });
            setData(filteredData);
            console.log("Filtered Data Found...");
        } catch (error) {
            console.log("Data Not Found...", error);
        }
    };    
    useEffect(() => {
        axios.get(`${hostlink}/${usershopid}/WasherMas`)
            .then((Response) => {
                setFebData(Response.data);
            })
            .catch((Response) => { "data Not Found" })

        axios.get(`${hostlink}/${usershopid}/WasherMas`)
            .then((Response) => {
                setWData(Response.data);
            })
            .catch((Response) => { "data Not Found" })
    }, [])

    const exportToExcel = () => {
        const ws = XLSX.utils.table_to_sheet(tableRef.current);
        const boldAndColorStyle = {
            font: { bold: true },
            fill: { fgColor: { rgb: "FFFF00" } }
        };
        const headerRow = XLSX.utils.decode_range(ws['!ref']).e.c;
        for (let c = 0; c <= headerRow; c++) {
            const cellAddress = XLSX.utils.encode_cell({ r: 0, c });
            if (ws[cellAddress].v === 'Sr No') {
                ws[cellAddress].s = boldAndColorStyle;
                for (let r = 1; r <= XLSX.utils.decode_range(ws['!ref']).e.r; r++) {
                    const dataCellAddress = XLSX.utils.encode_cell({ r, c });
                    if (ws[dataCellAddress]) {
                        ws[dataCellAddress].s = boldAndColorStyle;
                    }
                }
                break;
            }
        }
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

        XLSX.writeFile(wb, 'Washing Report.xlsx');
    };

    return (
        <div className='m-1'>
            <div className='container p-3 shadow rounded' style={{ transition: 'box-shadow 0.3s' }}>
                <div className='row'>
                    <div className='col-12 col-md-12 align-self-center p-1'>
                        <h5 className='font-monospace text-primary'> <i class="fa-solid fa-recycle"></i> <span id='pagemode'> Washing Report</span></h5>
                    </div>

                    <div className="col-12 col-md-1 ">Washer :</div>
                    <div className="col-12 col-md-2">
                        <select id="fabname" onChange={(e) => {
                            const selectedIndex = e.target.selectedIndex;
                            const fabcode = e.target.value;
                            const fabname = e.target.options[selectedIndex].text; onChangevalueFab(fabcode, fabname);
                        }} value={itemdetail.fabcode || 0} name="fabname" className="form-select">
                            <option key={0} value={0}>All</option>{FebData.map((x) => (<option key={x.id} value={x.id}>{x.wname}</option>))}
                        </select>
                    </div>
                        {/* <div className="col-12 col-md-1 ">Washer :</div>
                        <div className="col-12 col-md-2">
                            <select id="Washername" onChange={(e) => {
                                const selectedIndex = e.target.selectedIndex;
                                const Washercode = e.target.value;
                                const Washername = e.target.options[selectedIndex].text; onChangevalueWasher(Washercode, Washername);
                            }} value={itemdetail.Washercode || 0} name="Washername" className="form-select">
                                <option key={0} value={0}>All</option>{WData.map((x) => (<option key={x.id} value={x.id}>{x.wname}</option>))}
                            </select>
                        </div> */}
                    <div className='col-md-1 align-self-center text-center'>
                        <label htmlFor="fromdate" className="form-label">From Date:</label>
                    </div>
                    <div className='col-md-2'>
                        <input type="date" id="fromdate" name='fromdate' onChange={(e) => { onChangevalue(e) }} value={fromdate} className="form-control" />
                    </div>
                    <div className='col-md-1 align-self-center text-center'>
                        <label htmlFor="todate" className="form-label">To Date:</label>
                    </div>
                    <div className='col-md-2'>
                        <input type="date" id="todate" name='todate' onChange={(e) => { onChangevalue(e) }} value={todate} className="form-control" />
                    </div>
                    <div className='col-md-1 align-self-center text-center'>
                        <label htmlFor="roomtype" className="form-label">Status:</label>
                    </div>
                    <div className='col-md-2'>
                        <select id="roomtype" onChange={(e) => { onChangevalue(e) }} name='status' value={status} className="form-select">
                            <option value={0}>All</option>
                            <option value={1}>Pending</option>
                            <option value={2}>Received</option>
                        </select>
                    </div>
                    <div className='col-md-12 align-self-center text-end mt-2'>
                        <button onClick={() => { loaddata(1); }} className='btn btn-sm btn-outline-primary me-2' style={{ transition: 'background-color 0.3s, color 0.3s' }}><i className="fa-solid fa-magnifying-glass-chart"></i> Show </button>
                        <button onClick={() => { exportToExcel(); }} className='btn btn-sm btn-outline-primary me-2' style={{ transition: 'background-color 0.3s, color 0.3s' }}><i className="fa-solid fa-table"></i> Excel</button>
                        <PDFDownloadLink document={<PDFDocument data={data} fromdate={fromdate} todate={todate} />} fileName="Fabricator Report.pdf">
                            {({ blob, url, loading, error }) =>
                                loading ? 'Loading' : <button className='btn btn-sm btn-outline-primary' style={{ transition: 'background-color 0.3s, color 0.3s' }}><i className="fa-regular fa-file-pdf"></i> PDF</button>
                            }
                        </PDFDownloadLink>
                    </div>
                </div>
            </div>


            <br />
            {/* maxHeight: "400px", */}
            <div className='container-fluid f-size-on-mobile' style={{ overflow: "auto" }}>
                <table className="table table-hover table-striped " ref={tableRef}>

                    <thead className='text-center f-size-on-mobile' >
                        <tr >
                            <th className='text-start' scope="col">Lot No</th>
                            <th className='text-start' scope="col">Washer</th>
                            <th className='text-start' scope="col">Style</th>
                            <th className='text-start' scope="col">Size</th>
                            <th className='text-end' scope="col">In Qty</th>
                            <th className='text-end' scope="col">Out Qty</th>
                            <th className='text-end' scope="col">Pending</th>
                        </tr>
                    </thead>
                    <tbody className='text-center'>
                        {data.map((res, x) => {
                            // Compare the current value with the previous one
                            const isDuplicate = x > 0 && res.billno === data[x - 1].billno;
                            return (
                                <tr key={x}>
                                    <td className='text-start'>{res.purchaseMas.billno}</td>
                                    <td className='text-start'>{res.wasName}</td>
                                    <td className='text-start'>{res.purchaseMas.style}</td>
                                    <td className='text-start'>{res.purchaseMas.size}</td>
                                    <td className='text-end'>{res.stkInfab}</td>
                                    <td className='text-end'>{res.inQty*-1}</td>
                                    <td className='text-end'>{Number(res.stkInfab)+Number(res.inQty)}</td>
                                </tr>
                            );
                        })}

                        <tr class="table-info f-size-on-mobile">
                            <td></td>
                            <td></td>
                            <td></td>
                            <td className='text-end'><b>Total :</b></td>
                            <td className='text-end'><b>{data.reduce((total, res) => total + parseFloat(Number(res.stkInfab)), 0)}</b></td>
                            <td className='text-end'><b>{data.reduce((total, res) => total + parseFloat(Number(res.inQty * -1)), 0)}</b></td>
                            <td className='text-end'><b>{data.reduce((total, res) => total + parseFloat(Number(res.stkInfab)+Number(res.inQty)), 0)}</b></td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    )
}
const PDFDocument = ({ data, fromdate, todate }) => {
    // Calculate total bill amount
    // const totalBillAmount = data.reduce((total, res) => total + parseFloat(res.SaleMasDTO.totblamt), 0);
    return (
        <Document>
            <Page orientation="landscape">
                <View style={styles.container}>
                    <Text style={styles.title}>Washing Report</Text>
                    <Text style={styles.title}>From : {fromdate} To : {todate}</Text>
                    <View style={styles.table}>
                        <View style={styles.tableRow}>
                            <Text style={styles.headerCell}>Lot No</Text>
                            <Text style={styles.headerCell}>Washer</Text>
                            <Text style={styles.headerCell}>Style</Text>
                            <Text style={styles.headerCell}>Size</Text>
                            <Text style={styles.headerCell}>In Qty</Text>
                            <Text style={styles.headerCell}>Out Qty</Text>
                            <Text style={styles.headerCell}>Pending</Text>
                        </View>
                        {data.map((res, x) => {
                            // Compare the current value with the previous one
                            return (
                                <View key={x} style={styles.tableRow}>
                                    <Text style={styles.cell}>{res.purchaseMas.billno}</Text>
                                    <Text style={styles.cell}>{res.wasName}</Text>
                                    <Text style={styles.cell}>{res.purchaseMas.style}</Text>
                                    <Text style={styles.cell}>{res.purchaseMas.size}</Text>
                                    <Text style={styles.cell}>{res.stkInfab}</Text>
                                    <Text style={styles.cell}>{res.inQty*-1}</Text>
                                    <Text style={styles.cell}>{Number(res.stkInfab)+Number(res.inQty)}</Text>
                                </View>
                            );
                        })}
                        <View style={styles.tableRow}>
                            <Text style={styles.cell}></Text>
                            <Text style={styles.cell}></Text>
                            <Text style={styles.cell}></Text>
                            <Text style={styles.cell}>Total :</Text>
                            <Text style={styles.cell}>{data.reduce((total, res) => total + parseFloat(Number(res.stkInfab)), 0)}</Text>
                            <Text style={styles.cell}>{data.reduce((total, res) => total + parseFloat(Number(res.inQty * -1)), 0)}</Text>
                            <Text style={styles.cell}>{data.reduce((total, res) => total + parseFloat(Number(res.stkInfab)+Number(res.inQty)), 0)}</Text>
                        </View>
                    </View>
                </View>
            </Page>
        </Document>
    );
};

const styles = StyleSheet.create({
    container: {
        padding: 20,
    },
    title: {
        fontSize: 16,
        fontStyle: 'Times New Roman',
        marginBottom: 20,
        textAlign: 'center',
    },
    table: {
        display: 'table',
        width: '100%',
    },
    tableRow: {
        flexDirection: 'row',
        borderBottomWidth: 1,
        borderColor: '#000',
    },
    headerCell: {
        fontSize: 8,
        fontWeight: 'bold',
        padding: 5,
        flex: 1,
        fontStyle: 'Times New Roman',
        textAlign: 'center',
    },
    cell: {
        fontSize: 8,
        padding: 5,
        flex: 1,
        fontStyle: 'Times New Roman',
        textAlign: 'center',
    },
});


export default Rpt_WashReport