import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify';
import hostlink from '../Hostlink/hostlink';

import axios from 'axios';
import Modal from 'react-modal';
import PrintKot from './PrintKot';


const KotView = () => {
    const fs_cname = localStorage.getItem('MYPCKARTUSER') ? JSON.parse(localStorage.getItem('MYPCKARTUSER'))[0].fs_cname : '36';
    const usershopid = localStorage.getItem('MYPCKARTUSER') ? JSON.parse(localStorage.getItem('MYPCKARTUSER'))[0].id : '0';
    const [PrintClicked, setPrintClicked] = useState(false);

    const printdata = async (e) => {
        try {
            const response = await axios.get(`${hostlink}/KotviewOne/${usershopid}/${e}`);
            if (response && response.data && response.data.length > 0) {
                const printContent = generatePrintContent(response.data);
                const data = `1,${hostlink}/KotviewOne/${usershopid}/${e},${printContent}`;
                console.log(data)
                // Check if ReactNativeWebView is available
                if (window.ReactNativeWebView) {
                  window.ReactNativeWebView.postMessage(data);
                  return false;
                }
                // Create a new print window (iframe instead of new window)
                const printWindow = document.createElement('iframe');
                printWindow.style.position = 'absolute';
                printWindow.style.width = '0';
                printWindow.style.height = '0';
                printWindow.style.border = 'none';
                document.body.appendChild(printWindow);
                const doc = printWindow.contentWindow.document;
                doc.open();
                doc.write(printContent);
                doc.close();
    
                // Set CSS for printing
                const style = doc.createElement('style');
                style.innerHTML = `
                    @media print {
                        body, html {
                            width: 80mm;
                            margin: 0; /* Remove margins */
                            padding: 0; /* Remove padding */
                        }
                        * {
                            box-sizing: border-box; /* Include padding and border in element's width and height */
                        }
                        @page {
                            margin: 0; /* Remove margin */
                            size: 80mm 297mm; /* Set size to 80mm width */
                        }
                    }
                `;
                doc.head.appendChild(style);
    
                // Ensure the content is fully loaded before printing
                printWindow.onload = () => {
                    const contentHeight = doc.body.scrollHeight;
                    console.log("Content height:", contentHeight);
                    printWindow.contentWindow.print();
                    if (contentHeight > 210) {
                        doc.body.innerHTML += '\x1D\x56\x00'; // Manual cut command
                    }
                };
            } else {
                console.error("Empty or invalid response received.");
            }
        } catch (error) {
            console.error("Error fetching or printing data:", error);
        }
    };
    
    

    const generatePrintContent = (data) => {
        const tableInfo = data.length > 0 && data[0].tablename !== null ? `<span style="text-align: end; width:50%;"><p1 style="text-align: end; width:50%;">Table : ${data[0].tablename}</p1></span>` : '';
        return `<style>*{margin: 0;padding: 0;box-sizing: border-box;}div{width: 3in;}hr{width: 3in;}</style><div style="font-family: 'Bahnschrift Condensed'; width: 3in;"><p1 style="display: block; margin: 0 auto;text-align: center;">${data.length > 0 ? data[0].KottypeName : ''}</p1>
        <img style="display: block; margin: 0 auto;" src="./logo${usershopid}.png" alt="" />
        <h1 style="text-align: center;font-Size:${fs_cname}px;">${localStorage.getItem('MYPCKARTUSER') ? JSON.parse(localStorage.getItem('MYPCKARTUSER'))[0].cname : '0'}</h1><hr style="borderTop: 1px solid black; width: 3in;" /><span style="display:flex;"><p1 style="text-align: start; width:50%;">Kot No : ${data.length > 0 ? data[0].kotMasDTO.shopvno : ''}</p1>${tableInfo}</p1></span><span style="display:flex; width: 3in;"><p1 style="text-align: start; width:50%;">Date : ${data[0].kotMasDTO.kotdate ? new Date(data[0].kotMasDTO.kotdate).toLocaleDateString('en-GB', { day: '2-digit', month: '2-digit', year: '2-digit' }).replace(/\//g, '/') : "No data"}</p1><p1 style="text-align: end; width:50%;"> Time : ${data[0].kotMasDTO.kottime ? new Date(`2022-01-01T${data[0].kotMasDTO.kottime}`).toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit', hour12: true }) : "-"}</p1></span><table style="border-Top: 1px solid black; width: 3in;"><tr style="border-bottom: 1px solid black;"><td style="width: 10%;border-bottom: 1px solid black;">SN</td><td style="width: 80%;border-bottom: 1px solid black;">Item Name</td><td style="width: 10%;border-bottom: 1px solid black; text-align: end;">Qty</td></tr>
          ${data.map((x, index) => `<tr style="width: 100%"><td style="width: 10%">${index + 1}</td><td style="width: 80%">${x.kotMasDTO.itname}</td><td style="width: 10%; text-align: end;">${x.kotMasDTO.qty.toFixed(2)}</td></tr>`).join('')}</table><hr style="borderTop: 1px solid black; width: 3in;" /><span style="display:flex;"><p1 style="text-align: end; width:100%;">Total Qty : ${Number(data.reduce((total, resp) => total + parseFloat(resp.kotMasDTO.qty), 0)).toFixed(2)}</p1></span><hr style="borderTop: 1px solid black; width: 3in;" />      
        </div>`;
    };

    const [itemdetailGridRefresh, setitemdetailGridRefresh] = useState(false);
    const [srvdata, setsrvData] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [messagedata, setMessagedata] = useState({
        deleteid: "",
        status: "",
        deletemsg: ""
    })
    const { deleteid, deletemsg } = messagedata;
    const handleOpenModal = (id, Message, status) => {
        setMessagedata({ ...messagedata, deleteid: id, deletemsg: Message, status: status })
        setIsModalOpen(true);
    };
    const handleCloseModal = () => { setIsModalOpen(false); };
    const handleNoClick = () => { console.log('User clicked No'); handleCloseModal(); };
    const today = new Date();
    const [frmdata, setfrmdata] = useState({
        fromdate: today.toISOString().split('T')[0],
        todate: today.toISOString().split('T')[0],
        status: 3
    });
    const { fromdate, todate, status } = frmdata;
    const onChangevalueloaddata = (e) => {
        const { name, value } = e.target;
        const sanitizedValue = value.replace(/['"!`@#$%^&*+{}|;:,.<>?=]/g, '');
        setfrmdata({ ...frmdata, [name]: sanitizedValue });
        setitemdetailGridRefresh(true)
    }
    const searchData = (e) => {
        const searchValue = document.getElementById("searchroom").value.trim();
        console.log(searchValue)
        if (searchValue === "") {
            axios.get(`${hostlink}/kotview/${usershopid}/${fromdate}/${todate}`)
                .then((Response) => {
                    setsrvData(Response.data)
                    setitemdetailGridRefresh(false)
                })
                .catch(() => { })
        } else {
            axios.get(`${hostlink}/kotviewsearch/${usershopid}/${fromdate}/${todate}/${searchValue}`)
                .then((Response) => {
                    setsrvData(Response.data)
                    setitemdetailGridRefresh(false)
                })
                .catch(() => { })
        }
    }
    const cmdDelete = (vno, status) => {
        if (status === 'No') {
            toast.error("Bill Generated, You Cant't Delete..")
            return false
        }
        axios.delete(`${hostlink}/kotdelete/${usershopid}/${vno}`)
            .then((resp) => {
                toast.success("Delete Sucessfull")
                setTimeout(() => {
                    setitemdetailGridRefresh(true)
                    setIsModalOpen(false);
                }, 1000);
            })
            .catch((resp) => {
                console.log("Something Else")
            })

    }

    useEffect(() => {
        axios.get(`${hostlink}/kotview/${usershopid}/${fromdate}/${todate}`)
            .then((Response) => {
                setsrvData(Response.data)
                setitemdetailGridRefresh(false)
            })
            .catch(() => { })
    }, [itemdetailGridRefresh])
    return (
        <div>
            {/* {PrintClicked && <PrintKot arrayToPrint={srvdata} />}  */}

            {PrintClicked && <PrintKot arrayToPrint={srvdata} />}
            <div id='viewform'>
                <div className='container p-2'>
                    <div className='row p-1'>
                        <div className='col-md-2 p-1'>
                            <h5 className='font-monospace'><i class="fa-solid fa-bowl-food"></i> KOT View</h5>
                        </div>
                        <div className='col-md-2 rounded' style={{ display: "flex", alignItems: "center", background: "rgb(245,245,246)", height: "40px" }}>
                            <i class="fa-solid fa-magnifying-glass " ></i> &nbsp;&nbsp; <input type="text" class="" name="searchroom" onChange={(e) => { searchData(e) }} id="searchroom" placeholder="Search Kot" style={{ background: "rgb(245,245,246)", outline: "none", border: "0", width: "100%" }} /> <br />
                        </div>

                        <div className='col-md-6 align-self-end' style={{ display: "flex", flexDirection: "row", justifyContent: "center" }}>
                            <span className="f-size-on-mobile align-self-end" style={{ verticalAlign: 'middle', height: '100%', }}>
                                <label htmlFor="todate" className="form-label text-center">From : &nbsp;</label>
                            </span>
                            <span> <input type="date" id="fromdate" name="fromdate" onChange={(e) => { onChangevalueloaddata(e) }} value={fromdate} className="form-control" /></span>


                            <span className="f-size-on-mobile align-self-end">
                                <label htmlFor="todate" className="form-label text-center">&nbsp; To : &nbsp;</label>
                            </span>
                            <span>
                                <input type="date" id="todate" name="todate" onChange={(e) => { onChangevalueloaddata(e) }} value={todate} className="form-control" />
                            </span>
                        </div>


                        <div className='col-md-2 text-end'>

                        </div>
                        <div style={{ maxHeight: "80vh", overflow: "auto" }} className='col-12 '>
                            <table className="table table-hover table-striped">
                                <thead>
                                    <tr>
                                        <th scope="col" className="f-size-on-mobile"><span className='hide-on-mobile'>Date</span> <span className='show-on-mobile'>Details</span></th>
                                        <th scope="col" className="hide-on-mobile">Time</th>
                                        <th scope="col" className="hide-on-mobile">Kot No</th>
                                        <th scope="col" className="hide-on-mobile">Table</th>
                                        <th scope="col" className="hide-on-mobile">Ord Type</th>
                                        <th scope="col" className="hide-on-mobile">BlNo</th>
                                        <th scope="col" className="hide-on-mobile">Item</th>
                                        <th scope="col" className="hide-on-mobile">Ord Qty</th>
                                        <th scope="col" className="hide-on-mobile">Ord Amt</th>
                                        <th scope="col" className="hide-on-mobile">Is Bill</th>
                                        <th scope="col" className="f-size-on-mobile text-center">Action</th>
                                    </tr>
                                </thead>

                                <tbody>
                                    {srvdata.map((res, x) => (
                                        <tr style={{ verticalAlign: 'middle', height: '100%' }}>
                                            <td className="f-size-on-mobile" style={{ wordWrap: "break-word" }}> <span className='show-on-mobile'><b>Date :</b> </span> {res.kotMasDTO.kotdate ? new Date(res.kotMasDTO.kotdate).toLocaleDateString('en-GB', { day: '2-digit', month: '2-digit', year: '2-digit' }).replace(/\//g, '/') : "No data"} <br />
                                                <div className="detail-on-mobile text-start align-self-start" style={{ fontSize: "12px", color: "blue", alignItems: "start", wordWrap: "break-word" }}>
                                                    {res.kotMasDTO.kottime !== 0 && (<>Time : {res.kotMasDTO.kottime ? new Date(`2022-01-01T${res.kotMasDTO.kottime}`).toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit', hour12: true }) : "-"} <br /></>)}

                                                    {res.kotMasDTO.itemview !== '' && (<>Item : {res.kotMasDTO.itemview} <br /></>)}
                                                    {res.kotMasDTO.totordamt !== 0 && (<>Order Amount : {res.kotMasDTO.totordamt} <br /></>)} <span style={{ color: "blue" }}> </span>
                                                    {res.KottypeName !== '' && (<>Type : {res.KottypeName} <br /></>)}
                                                    <span className='text-bg-danger'>{res.kotMasDTO.tablename !== '' && (<>Table Name : {res.kotMasDTO.tablename} <br /></>)}</span>
                                                </div>
                                            </td>
                                            
                                            <td className="hide-on-mobile" style={{ wordWrap: "break-word" }}>{res.kotMasDTO.kottime ? new Date(`2022-01-01T${res.kotMasDTO.kottime}`).toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit', hour12: true }) : "-"}</td>
                                            <td className="hide-on-mobile" style={{ wordWrap: "break-word" }}>{res.kotMasDTO.shopvno}</td>
                                            <td className="hide-on-mobile" style={{ wordWrap: "break-word" }}>{res.tablename}</td>
                                            <td className="hide-on-mobile" style={{ wordWrap: "break-word" }}>{res.KottypeName}</td>
                                            <td className="hide-on-mobile" style={{ wordWrap: "break-word" }}>{res.kotMasDTO.blno}</td>
                                            <td className="hide-on-mobile" style={{ wordWrap: "break-word" }}>{res.kotMasDTO.itemview}</td>
                                            <td className="hide-on-mobile" style={{ wordWrap: "break-word" }}>{res.kotMasDTO.totqty}</td>
                                            <td className="hide-on-mobile" style={{ wordWrap: "break-word" }}>{res.kotMasDTO.totordamt}</td>
                                            <td className="hide-on-mobile" style={{ wordWrap: "break-word" }}>{res.statusname}</td>
                                            <td className='text-center' style={{ verticalAlign: 'middle', height: '100%' }}>
                                                <Link className='btn btn-sm btn-danger' onClick={(e) => { handleOpenModal(res.kotMasDTO.shopvno, res.kotMasDTO.itemview, res.statusname) }} > <i class="fa-solid fa-trash"></i> <span className="hide-on-mobile"> Delete</span></Link> &nbsp;
                                                <Link className='btn btn-sm btn-outline-primary' onClick={(e) => { printdata(res.kotMasDTO.shopvno,) }}><i class="fa-solid fa-print"></i> <span className="hide-on-mobile">Print</span></Link> &nbsp;
                                                {/* <Link className='btn btn-sm btn-outline-primary' onClick={(e)=>{printdata()}}><i class="fa-solid fa-print"></i> <span className="hide-on-mobile">Print</span></Link> &nbsp; */}
                                                {/* <button onClick={printdata}>Print</button> */}

                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            <Modal isOpen={isModalOpen} onRequestClose={handleCloseModal} contentLabel="Delete Confirmation" className="modal-content" overlayClassName="modal-overlay">
                <div><p>Are you sure you want to delete this <br /> Kot, Item : {deletemsg} ?</p><div className="button-container"><button className="yes-button" onClick={(e) => { cmdDelete(deleteid, status) }}>Yes</button> <button className="no-button" onClick={handleNoClick}>No</button></div></div>
            </Modal>
            <ToastContainer autoClose={1000}></ToastContainer>
        </div>
    )
}

export default KotView