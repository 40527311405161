import React, { useEffect, useRef, useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import axios from 'axios';
import hostlink from '../Hostlink/hostlink';
import * as XLSX from 'xlsx';
import { PDFDownloadLink, PDFViewer, Document, Page, Text, View, StyleSheet } from '@react-pdf/renderer';
import Loading from './Loading'
const FindLot = () => {
    const [isLoading, setIsLoading] = useState(false);
    const usershopid = localStorage.getItem('MYPCKARTUSER') ? JSON.parse(localStorage.getItem('MYPCKARTUSER'))[0].id : '0'
    const [RoomType, setRoomType] = useState([]);
    const [Room, setRoom] = useState([]);
    const [deptdata, setdeptdata] = useState([])
    const [data, setData] = useState([]);
    const today = new Date();
    const [FebData, setFebData] = useState([])
    const [WData, setWData] = useState([])
    const [frmdata, setfrmdata] = useState({
        fromdate: today.toISOString().split('T')[0],
        todate: today.toISOString().split('T')[0],
        deptcode: 0,
        lotno: "",
        status: 1
    });
    const [totalSums, setTotalSums] = useState({});
    const [itemdetail, setitemdetail] = useState({ Washername: "", Washercode: 0, fabname: "", fabcode: 0 });
    const { fabcode, fabname, Washercode, Washername, } = itemdetail;

    const onChangevalueFab = (fabcode, fabname) => {
        setitemdetail({
            ...itemdetail,
            fabcode: fabcode,
            fabname: fabname
        });
    };

    const onChangevalueWasher = (Washercode, Washername) => {
        setitemdetail({
            ...itemdetail,
            Washercode: Washercode,
            Washername: Washername
        });
    };
    const { fromdate, todate, status, deptcode, lotno } = frmdata;
    const tableRef = useRef(null);
    const onChangevalue = (e) => {
        const { name, value } = e.target;
        const sanitizedValue = value.replace(/['"!`@#$%^&*+{}|;:,.<>?=]/g, '');
        setfrmdata({ ...frmdata, [name]: sanitizedValue });
    };

    // if(lotno==""){
    //     toast.error("Enter Lot No...");
    //     return false;
    // }
    const loaddata = async () => {
        setIsLoading(true);    
        try {
            const apidata = lotno === "" ? `PurchaseMasFindAll/${usershopid}` : `PurchaseMasFindAllSearch/${usershopid}/${lotno}`;


            const billResponse = await axios.get(`${hostlink}/${apidata}`);
            const data = billResponse.data;

            const fabnameDataMap = {};
            const wnameDataMap = {};
            const finisharDataMap = {};  // Add a new map for finisharname

            data.forEach(item => {
                if (item.fabname) {
                    const fabKey = `${item.fabcode}-${item.size}-${item.style}`;
                    if (!fabnameDataMap[fabKey]) {
                        fabnameDataMap[fabKey] = {
                            type: 'fabname',
                            fabname: item.fabname,
                            size: item.size,
                            style: item.style,
                            billno: item.billno,
                            pcs: item.pcs,
                            fabcode: item.fabcode
                        };
                    } else {
                        fabnameDataMap[fabKey].pcs += item.pcs;
                    }
                }

                if (item.wname) {
                    const wKey = `${item.wcode}-${item.size}-${item.style}`;
                    if (!wnameDataMap[wKey]) {
                        wnameDataMap[wKey] = {
                            type: 'wname',
                            wname: item.wname,
                            size: item.size,
                            style: item.style,
                            billno: item.billno,
                            washqty: item.washqty,
                            wcode: item.wcode
                        };
                    } else {
                        wnameDataMap[wKey].washqty += item.washqty;
                    }
                }

                if (item.finisharname) {
                    // Handle finisharcode, finisharname, and finishqty
                    const finisharKey = `${item.finisharcode}-${item.size}-${item.style}`;
                    if (!finisharDataMap[finisharKey]) {
                        finisharDataMap[finisharKey] = {
                            type: 'finisharname',
                            finisharname: item.finisharname,
                            size: item.size,
                            style: item.style,
                            billno: item.billno,
                            finishqty: item.finishqty,  // Start with the current finishqty value
                            finisharcode: item.finisharcode
                        };
                    } else {
                        finisharDataMap[finisharKey].finishqty += item.finishqty;
                    }
                }
            });

            // Convert the maps back to arrays and filter out entries with zero pcs, washqty, or finishqty
            const fabnameData = Object.values(fabnameDataMap).filter(item => item.pcs !== 0);
            const wnameData = Object.values(wnameDataMap).filter(item => item.washqty !== 0);
            const finisharData = Object.values(finisharDataMap).filter(item => item.finishqty !== 0);

            // Combine fabnameData, wnameData, and finisharData
            const result = [...fabnameData, ...wnameData, ...finisharData];

            console.log("Filtered Data Found...", result);
            setData(result);  // Set the combined data as the new state
            setIsLoading(false);   
        } catch (error) {
            console.log("Data Not Found...", error);
        }
    };





    useEffect(() => {
        axios.get(`${hostlink}/${usershopid}/FabMasCont`)
            .then((Response) => {
                setFebData(Response.data);
            })
            .catch((Response) => { "data Not Found" })

        axios.get(`${hostlink}/${usershopid}/WasherMas`)
            .then((Response) => {
                setWData(Response.data);
            })
            .catch((Response) => { "data Not Found" })
    }, [])

    const exportToExcel = () => {
        const ws = XLSX.utils.table_to_sheet(tableRef.current);
        const boldAndColorStyle = {
            font: { bold: true },
            fill: { fgColor: { rgb: "FFFF00" } }
        };
        const headerRow = XLSX.utils.decode_range(ws['!ref']).e.c;
        for (let c = 0; c <= headerRow; c++) {
            const cellAddress = XLSX.utils.encode_cell({ r: 0, c });
            if (ws[cellAddress].v === 'Sr No') {
                ws[cellAddress].s = boldAndColorStyle;
                for (let r = 1; r <= XLSX.utils.decode_range(ws['!ref']).e.r; r++) {
                    const dataCellAddress = XLSX.utils.encode_cell({ r, c });
                    if (ws[dataCellAddress]) {
                        ws[dataCellAddress].s = boldAndColorStyle;
                    }
                }
                break;
            }
        }
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

        XLSX.writeFile(wb, 'Pending Lot.xlsx');
    };

    return (
        <div className='m-1'>
            <div className='container p-3 shadow rounded' style={{ transition: 'box-shadow 0.3s' }}>
                <div className='row'>
                    <div className='col-12 col-md-12 align-self-center p-1'>
                        <h5 className='font-monospace text-primary'> <i class="fa-solid fa-magnifying-glass"></i> <span id='pagemode'> Pending Lot</span></h5>
                    </div>

                    
                    <div className='col-md-1'>
                        <label htmlFor="roomtype" className="form-label">Lot No:</label>
                    </div>
                    <div className='col-md-2'>
                        <input type="text" id="lotno" name='lotno' onChange={(e) => { onChangevalue(e) }} value={lotno} className="form-control" />
                    </div>
                   
                    <div className='col-md-12 align-self-center text-end mt-2'>
                        <button onClick={() => { loaddata(1); }} className='btn btn-sm btn-outline-primary me-2' style={{ transition: 'background-color 0.3s, color 0.3s' }}><i className="fa-solid fa-magnifying-glass-chart"></i> Show </button>
                        <button onClick={() => { exportToExcel(); }} className='btn btn-sm btn-outline-primary me-2' style={{ transition: 'background-color 0.3s, color 0.3s' }}><i className="fa-solid fa-table"></i> Excel</button>
                        <PDFDownloadLink document={<PDFDocument data={data} fromdate={fromdate} todate={todate} />} fileName="Pending Lot.pdf">
                            {({ blob, url, loading, error }) =>
                                loading ? 'Loading' : <button className='btn btn-sm btn-outline-primary' style={{ transition: 'background-color 0.3s, color 0.3s' }}><i className="fa-regular fa-file-pdf"></i> PDF</button>
                            }
                        </PDFDownloadLink>
                    </div>
                </div>
            </div>


            <br />
            {/* maxHeight: "400px", */}
            <div className='container-fluid f-size-on-mobile' style={{ overflow: "auto" }}>
                <table className="table table-hover table-striped " ref={tableRef}>

                    <thead className='text-center f-size-on-mobile' >
                        <tr >
                            <th className='text-start' scope="col">Lot No</th>
                            <th className='text-start' scope="col">Type</th>
                            <th className='text-start' scope="col">Name</th>
                            <th className='text-end' scope="col">Style</th>
                            <th className='text-end' scope="col">Size</th>
                            <th className='text-end' scope="col">Pending Pcs</th>
                        </tr>
                    </thead>
                    <tbody className='text-center'>
                        {data.map((res, x) => {
                            // Compare the current value with the previous one
                            const isDuplicate = x > 0 && res.billno === data[x - 1].billno;

                            // Combine fabname, wname, and finisharname into one column
                            const combinedNames = [
                                res.fabname ? `${res.fabname}` : '',
                                res.wname ? `${res.wname}` : '',
                                res.finisharname ? `${res.finisharname}` : ''
                            ].filter(Boolean).join(' | ');

                            // Combine fabname, wname, and finisharname labels
                            const combinedType = [
                                res.fabname ? `Fabricator` : '',
                                res.wname ? `Washing` : '',
                                res.finisharname ? `Finishing` : ''
                            ].filter(Boolean).join(' | ');

                            // Combine pcs, washqty, and finishqty
                            const pendingpcs = [
                                res.fabname ? `${res.pcs}` : '',
                                res.wname ? `${res.washqty}` : '',
                                res.finishqty ? `${res.finishqty}` : ''
                            ].filter(Boolean).join(' | ');

                            return (
                                <tr key={x}>
                                    <td className='text-start'>{res.billno}</td>
                                    <td className='text-start'>{combinedType}</td> {/* Combine types */}
                                    <td className='text-start'>{combinedNames}</td> {/* Combine fabname, wname, and finisharname */}
                                    <td className='text-end'>{res.style}</td>
                                    <td className='text-end'>{res.size}</td>
                                    <td className='text-end'>{pendingpcs}</td> {/* Combine pcs, washqty, and finishqty */}
                                </tr>
                            );
                        })}

                        <tr class="table-info f-size-on-mobile">
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td className='text-end'><b>Total :</b></td>
                            <td className='text-end'>
                                <b>
                                    {data.reduce((total, res) => {
                                        // Add pcs if fabname exists
                                        const pcsSum = res.fabname ? parseFloat(res.pcs) : 0;
                                        // Add washqty if wname exists
                                        const washqtySum = res.wname ? parseFloat(res.washqty) : 0;
                                        // Add finishqty if finisharname exists
                                        const finishqtySum = res.finisharname ? parseFloat(res.finishqty) : 0;
                                        // Sum all values
                                        return total + pcsSum + washqtySum + finishqtySum;
                                    }, 0)}
                                </b>
                            </td>
                        </tr>
                    </tbody>

                </table>
            </div>
            <ToastContainer autoClose={1000}></ToastContainer>
            {isLoading && <Loading />}
        </div>
    )
}
const PDFDocument = ({ data, fromdate, todate }) => {
    // Calculate total bill amount
    // const totalBillAmount = data.reduce((total, res) => total + parseFloat(res.SaleMasDTO.totblamt), 0);
    return (
        <Document>
            <Page orientation="landscape">
                <View style={styles.container}>
                    <Text style={styles.title}>Pending Lot</Text>
                    
                    <View style={styles.table}>
                        <View style={styles.tableRow}>
                            <Text style={styles.headerCell}>Lot No</Text>
                            <Text style={styles.headerCell}>Type</Text>
                            <Text style={styles.headerCell}>Name</Text>
                            <Text style={styles.headerCell}>Style</Text>
                            <Text style={styles.headerCell}>Size</Text>
                            <Text style={styles.headerCell}>Pending Pcs</Text>
                        </View>
                        {data.map((res, x) => {
                              // Compare the current value with the previous one
                              const isDuplicate = x > 0 && res.billno === data[x - 1].billno;

                              // Combine fabname, wname, and finisharname into one column
                              const combinedNames = [
                                  res.fabname ? `${res.fabname}` : '',
                                  res.wname ? `${res.wname}` : '',
                                  res.finisharname ? `${res.finisharname}` : ''
                              ].filter(Boolean).join(' | ');
  
                              // Combine fabname, wname, and finisharname labels
                              const combinedType = [
                                  res.fabname ? `Fabricator` : '',
                                  res.wname ? `Washing` : '',
                                  res.finisharname ? `Finishing` : ''
                              ].filter(Boolean).join(' | ');
  
                              // Combine pcs, washqty, and finishqty
                              const pendingpcs = [
                                  res.fabname ? `${res.pcs}` : '',
                                  res.wname ? `${res.washqty}` : '',
                                  res.finishqty ? `${res.finishqty}` : ''
                              ].filter(Boolean).join(' | ');
  
                            return (
                                <View key={x} style={styles.tableRow}>
                                    <Text style={styles.cell}>{res.billno}</Text>
                                    <Text style={styles.cell}>{combinedType}</Text>
                                    <Text style={styles.cell}>{combinedNames}</Text>
                                    <Text style={styles.cell}>{res.style}</Text>
                                    <Text style={styles.cell}>{res.size}</Text>
                                    <Text style={styles.cell}>{pendingpcs}</Text>
                                    
                                </View>
                            );
                        })}
                        <View style={styles.tableRow}>
                            <Text style={styles.cell}></Text>
                            <Text style={styles.cell}></Text>
                            <Text style={styles.cell}></Text>
                            <Text style={styles.cell}></Text>
                            <Text style={styles.cell}>Total :</Text>
                            <Text style={styles.cell}>{data.reduce((total, res) => {
                                        // Add pcs if fabname exists
                                        const pcsSum = res.fabname ? parseFloat(res.pcs) : 0;
                                        // Add washqty if wname exists
                                        const washqtySum = res.wname ? parseFloat(res.washqty) : 0;
                                        // Add finishqty if finisharname exists
                                        const finishqtySum = res.finisharname ? parseFloat(res.finishqty) : 0;
                                        // Sum all values
                                        return total + pcsSum + washqtySum + finishqtySum;
                                    }, 0)}</Text>
                            
                        </View>
                    </View>
                </View>
            </Page>
        </Document>
    );
};

const styles = StyleSheet.create({
    container: {
        padding: 20,
    },
    title: {
        fontSize: 16,
        fontStyle: 'Times New Roman',
        marginBottom: 20,
        textAlign: 'center',
    },
    table: {
        display: 'table',
        width: '100%',
    },
    tableRow: {
        flexDirection: 'row',
        borderBottomWidth: 1,
        borderColor: '#000',
    },
    headerCell: {
        fontSize: 8,
        fontWeight: 'bold',
        padding: 5,
        flex: 1,
        fontStyle: 'Times New Roman',
        textAlign: 'center',
    },
    cell: {
        fontSize: 8,
        padding: 5,
        flex: 1,
        fontStyle: 'Times New Roman',
        textAlign: 'center',
    },
});


export default FindLot
