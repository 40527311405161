import React, { useState } from 'react';
import './Shop_Create.css'; // Import CSS file for styling
import axios from 'axios';
import hostlink from '../Hostlink/hostlink';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Modal from 'react-modal';
import emailjs from 'emailjs-com';
import Loading from './Loading'
import { Link } from 'react-router-dom';
const Passwordchange = () => {
    const usershopid = localStorage.getItem('MYPCKARTUSER') ? JSON.parse(localStorage.getItem('MYPCKARTUSER'))[0].id : '0'
    const [isLoading, setIsLoading] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [Mailotp, setMailotp] = useState('');

    const handleOpenModal = (e, flname) => {
        setIsModalOpen(true);
    };
    const handleCloseModal = () => { setIsModalOpen(false); };
    const handleNoClick = () => { console.log('User clicked No'); handleCloseModal(); };

    const [savedata, setSavedata] = useState({

        email: "",
        password: "",
        pass1:"",
        pass2:""
    });
    const { email, password, pass1, pass2 } = savedata;

    
    const updatepassword = async (e) => {
        setIsLoading(true);

        if (pass1 == pass2) {
            const nameQuery = `update shopmas set password = '${pass1}' where id = '${usershopid}'`;
            const nameResponse = await axios.post(`${hostlink}/api/executeUpdate?sqlQuery=${encodeURIComponent(nameQuery)}`);
            
            if (nameResponse.data == 'Rows affected: 1') {
                toast.success("Password change success..")
                setTimeout(() => {
                    window.location.assign("/")
                }, 2000);
                return false;
            }
            setIsLoading(false);
            toast.error("Something went wrong..");
            return false
        }
        else {
            setIsLoading(false);
            toast.error("Password doesn't match..")
            document.getElementById("pass1").focus();
            return false

        }
    }


    

  

    const onChangevalue = (e) => {
        const { name, value } = e.target;
        const sanitizedValue = value.replace(/['"!`$%^*+{}|;<>?=]/g, '');
        setSavedata({ ...savedata, [name]: sanitizedValue });
    };

    return (
        <div className="shop-create-container">
            <div className="background"></div>
            <div className='container p-3'>
                <div className=' text-start'>
                    <h1><i class="fa-solid fa-key"></i> Change Password..</h1> <br />
                </div>
                

                <div className='row'>
                        <label className='font-monospace' class="form-label">New Password</label>
                        <input type="email" onChange={(e) => { onChangevalue(e) }} name='pass1' value={pass1} class="form-control" id="pass1" />
                        <label className='font-monospace' class="form-label">Conform Password</label>
                        <input type="email" onChange={(e) => { onChangevalue(e) }} name='pass2' value={pass2} class="form-control" id="pass2" />
                        <Link onClick={() => { updatepassword() }} href="">Submit</Link>
                        <br />
                    
                </div>

            </div>
            <br />
            <footer className="fixed-bottom"> <marquee className='bg-dark text-light p-1' behavior="alternate" direction="left"> We offer customized software solutions that meet the requirements of your business. We have multiple software products with us with all required modules. (For more details, contact us at: +91-9205802778, Email: sujeetraj995@gmail.com) </marquee> </footer>
            <Modal isOpen={isModalOpen} onRequestClose={handleCloseModal} contentLabel="Delete Confirmation" className="modal-content" overlayClassName="modal-overlay">
                <div>
                    <img src="./please_wait.gif" alt="please wait creatig company..." style={{ width: "100%" }} />
                </div>
            </Modal>
            <ToastContainer autoClose={1000}></ToastContainer>
            {isLoading && <Loading />}
        </div>
    );
};

export default Passwordchange;
