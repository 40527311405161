import React, { useEffect, useRef, useState } from 'react';
import axios from 'axios';
import hostlink from '../Hostlink/hostlink';
import * as XLSX from 'xlsx';
import { PDFDownloadLink, PDFViewer, Document, Page, Text, View, StyleSheet } from '@react-pdf/renderer';

const Rpt_PurchaseReport = () => {
    const usershopid = localStorage.getItem('MYPCKARTUSER') ? JSON.parse(localStorage.getItem('MYPCKARTUSER'))[0].id : '0'
    const [RoomType, setRoomType] = useState([]);
    const [Room, setRoom] = useState([]);
    const [deptdata, setdeptdata] = useState([])
    const [data, setData] = useState([]);
    const today = new Date();
    const [frmdata, setfrmdata] = useState({
        fromdate: today.toISOString().split('T')[0],
        todate: today.toISOString().split('T')[0],
        deptcode: 0,
        status: 0
    });
    const [totalSums, setTotalSums] = useState({});

    const { fromdate, todate, status, deptcode } = frmdata;
    const tableRef = useRef(null);
    const onChangevalue = (e) => {
        const { name, value } = e.target;
        const sanitizedValue = value.replace(/['"!`@#$%^&*+{}|;:,.<>?=]/g, '');
        setfrmdata({ ...frmdata, [name]: sanitizedValue });
    };
    const loaddata = async (e) => {
        try {
                const billResponse = await axios.get(`${hostlink}/PurchaseMasviewrpt/0/${usershopid}/${fromdate}/${todate}`);
                const billData = billResponse.data;
                setData(billResponse.data)
            
        } catch (error) {
            console.log("Data Not Found...");
        }
    };
    useEffect(() => {
        // axios.get(`${hostlink}/${usershopid}/deptmas`)
        //     .then((Response) => { setdeptdata(Response.data) })
    }, [])

    const exportToExcel = () => {
        const ws = XLSX.utils.table_to_sheet(tableRef.current);
        const boldAndColorStyle = {
            font: { bold: true },
            fill: { fgColor: { rgb: "FFFF00" } }
        };
        const headerRow = XLSX.utils.decode_range(ws['!ref']).e.c;
        for (let c = 0; c <= headerRow; c++) {
            const cellAddress = XLSX.utils.encode_cell({ r: 0, c });
            if (ws[cellAddress].v === 'Sr No') {
                ws[cellAddress].s = boldAndColorStyle;
                for (let r = 1; r <= XLSX.utils.decode_range(ws['!ref']).e.r; r++) {
                    const dataCellAddress = XLSX.utils.encode_cell({ r, c });
                    if (ws[dataCellAddress]) {
                        ws[dataCellAddress].s = boldAndColorStyle;
                    }
                }
                break;
            }
        }
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

        XLSX.writeFile(wb, 'Purchase Report.xlsx');
    };

    return (
        <div className='m-1'>
            <div className='container p-3 shadow rounded' style={{ transition: 'box-shadow 0.3s' }}>
                <div className='row'>
                    <div className='col-md-6 align-self-center'>
                        <h5 className='font-monospace text-primary'> <i class="fa-solid fa-book"></i> <span id='pagemode'> Purchase Report</span></h5>
                    </div>
                    <div className='col-md-1 align-self-center text-center'>
                        <label htmlFor="fromdate" className="form-label">From Date:</label>
                    </div>
                    <div className='col-md-2'>
                        <input type="date" id="fromdate" name='fromdate' onChange={(e) => { onChangevalue(e) }} value={fromdate} className="form-control" />
                    </div>
                    <div className='col-md-1 align-self-center text-center'>
                        <label htmlFor="todate" className="form-label">To Date:</label>
                    </div>
                    <div className='col-md-2'>
                        <input type="date" id="todate" name='todate' onChange={(e) => { onChangevalue(e) }} value={todate} className="form-control" />
                    </div>
                    {/* <div className='col-md-1 align-self-center text-center'>
                        <label htmlFor="roomtype" className="form-label">Department:</label>
                    </div>
                    <div className="col-2 text-start-0 align-self-center">
                        <select id="deptcode" name='deptcode' value={deptcode} onChange={(e) => { onChangevalue(e) }} class="form-select">
                            <option selected key={0} value={0}>All</option>
                            {deptdata.map((x) => {
                                return (
                                    <option key={x.id} value={x.id}> {x.deptname} </option>
                                )
                            })}
                        </select>
                    </div> */}
                    <div className='col-md-12 align-self-center text-end mt-2'>
                        {/* <button onClick={() => { loaddata(0); }} className='btn btn-sm btn-outline-primary me-2' style={{ transition: 'background-color 0.3s, color 0.3s' }}><i className="fa-solid fa-magnifying-glass-chart"></i> Department-wise </button> */}
                        <button onClick={() => { loaddata(1); }} className='btn btn-sm btn-outline-primary me-2' style={{ transition: 'background-color 0.3s, color 0.3s' }}><i className="fa-solid fa-magnifying-glass-chart"></i> Show </button>
                        <button onClick={() => { exportToExcel(); }} className='btn btn-sm btn-outline-primary me-2' style={{ transition: 'background-color 0.3s, color 0.3s' }}><i className="fa-solid fa-table"></i> Excel</button>
                        <PDFDownloadLink document={<PDFDocument data={data} fromdate={fromdate} todate={todate} />} fileName="Purchase Report.pdf">
                            {({ blob, url, loading, error }) =>
                                loading ? 'Loading' : <button className='btn btn-sm btn-outline-primary' style={{ transition: 'background-color 0.3s, color 0.3s' }}><i className="fa-regular fa-file-pdf"></i> PDF</button>
                            }
                        </PDFDownloadLink>
                    </div>
                </div>
            </div>


            <br />
            {/* maxHeight: "400px", */}
            <div className='container-fluid f-size-on-mobile' style={{ overflow: "auto" }}>
                <table className="table table-hover table-striped " ref={tableRef}>

                    <thead className='text-center f-size-on-mobile' >
                        <tr >
                            <th className='text-start' scope="col">Bill No</th>
                            <th className='text-start' scope="col">Bill Date</th>
                            <th className='text-start' scope="col">Party</th>
                            <th className='text-start' scope="col">Sort No</th>
                            <th className='text-end' scope="col">Mtr</th>
                            <th className='text-end' scope="col">Rate</th>
                            <th className='text-end' scope="col">Roll</th>
                            <th className='text-end' scope="col">Bill Amount</th>
                        </tr>
                    </thead>
                    <tbody className='text-center'>
                        {data.map((res, x) => {
                            // Compare the current value with the previous one
                            const isDuplicate = x > 0 && res.billno === data[x - 1].billno;
                            return (
                                <tr key={x}>
                                    <td className='text-start'>{isDuplicate ? '' : res.billno}</td>
                                    <td className='text-start'>{isDuplicate ? '' : res.billdate ? new Date(res.billdate).toLocaleDateString('en-GB', { day: '2-digit', month: '2-digit', year: '2-digit' }).replace(/\//g, '/') : "-"}</td>
                                    <td className='text-start'>{isDuplicate ? '' : res.accname}</td>
                                    <td className='text-start'>{res.sortno}</td>
                                    <td className='text-end'>{res.mtr}</td>
                                    <td className='text-end'>{res.rate}</td>
                                    <td className='text-end'>{res.roll}</td>
                                    <td className='text-end'>{isDuplicate ? '' : res.billamt}</td>
                                </tr>
                            );
                        })}

                        <tr class="table-info f-size-on-mobile">
                            <td></td>
                            <td></td>
                            <td></td>
                            <td className='text-end'><b>Total :</b></td>
                            <td className='text-end'><b>{data.reduce((total, res) => total + parseFloat(Number(res.mtr)), 0)}</b></td>
                            <td></td>
                            <td className='text-end'><b>{data.reduce((total, res) => total + parseFloat(Number(res.roll)), 0)}</b></td>
                            <td className='text-end'><b>{data.reduce((total, res) => total + parseFloat(Number(res.mtr*res.rate)), 0)}</b></td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    )
}
const PDFDocument = ({ data, fromdate, todate }) => {
    // Calculate total bill amount
    // const totalBillAmount = data.reduce((total, res) => total + parseFloat(res.SaleMasDTO.totblamt), 0);
    return (
        <Document>
            <Page orientation="landscape">
                <View style={styles.container}>
                    <Text style={styles.title}>Purchase Report</Text>
                    <Text style={styles.title}>From : {fromdate} To : {todate}</Text>
                    <View style={styles.table}>
                        <View style={styles.tableRow}>
                            <Text style={styles.headerCell}>Bl No</Text>
                            <Text style={styles.headerCell}>Bl Date</Text>
                            <Text style={styles.headerCell}>Party</Text>
                            <Text style={styles.headerCell}>Sort No</Text>
                            <Text style={styles.headerCell}>Mtr</Text>
                            <Text style={styles.headerCell}>Rate</Text>
                            <Text style={styles.headerCell}>Roll</Text>
                            <Text style={styles.headerCell}>Bill Amount</Text>
                        </View>
                        {data.map((res, x) => {
                            // Compare the current value with the previous one
                            const isDuplicate = x > 0 && res.billno === data[x - 1].billno;
                            return (
                                <View key={x} style={styles.tableRow}>
                                <Text style={styles.cell}>{isDuplicate ? '' : res.billno}</Text>
                                <Text style={styles.cell}>{isDuplicate ? '' : res.billdate ? new Date(res.billdate).toLocaleDateString('en-GB', { day: '2-digit', month: '2-digit', year: '2-digit' }).replace(/\//g, '/') : "-"}</Text>
                                <Text style={styles.cell}>{isDuplicate ? '' : res.accname}</Text>
                                <Text style={styles.cell}>{res.sortno}</Text>
                                <Text style={styles.cell}>{res.mtr}</Text>
                                <Text style={styles.cell}>{res.rate}</Text>
                                <Text style={styles.cell}>{res.roll}</Text>
                                
                                <Text style={styles.cell}>{isDuplicate ? '' : res.billamt}</Text>
                            </View>
                            );
                        })}
                        <View style={styles.tableRow}>
                            <Text style={styles.cell}></Text>
                            <Text style={styles.cell}></Text>
                            <Text style={styles.cell}></Text>
                            <Text style={styles.cell}>Total :</Text>
                            <Text style={styles.cell}>{data.reduce((total, res) => total + parseFloat(Number(res.mtr)), 0)}</Text>
                            <Text style={styles.cell}></Text>
                            <Text style={styles.cell}>{data.reduce((total, res) => total + parseFloat(Number(res.roll)), 0)}</Text>
                            <Text style={styles.cell}>{data.reduce((total, res) => total + parseFloat(Number(res.mtr*res.rate)), 0)}</Text>
                        </View>
                    </View>
                </View>
            </Page>
        </Document>
    );
};

const styles = StyleSheet.create({
    container: {
        padding: 20,
    },
    title: {
        fontSize: 16,
        fontStyle: 'Times New Roman',
        marginBottom: 20,
        textAlign: 'center',
    },
    table: {
        display: 'table',
        width: '100%',
    },
    tableRow: {
        flexDirection: 'row',
        borderBottomWidth: 1,
        borderColor: '#000',
    },
    headerCell: {
        fontSize: 8,
        fontWeight: 'bold',
        padding: 5,
        flex: 1,
        fontStyle: 'Times New Roman',
        textAlign: 'center',
    },
    cell: {
        fontSize: 8,
        padding: 5,
        flex: 1,
        fontStyle: 'Times New Roman',
        textAlign: 'center',
    },
});



export default Rpt_PurchaseReport