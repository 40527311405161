import React from 'react'
import css from "./app.module.css"
import Navbar from './Component/Navbar'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import ItemUnit_View from './Component/ItemUnit_View'
import Home from './Component/Home'
import ItemGroupMaster from './Component/ItemGroupMaster'
import KitchenMaster from './Component/KitchenMaster'
import DishTypeMaster from './Component/DishTypeMaster'
import ItemCompanyMaster from './Component/ItemCompanyMaster'
import DishHeadMaster from './Component/DishHeadMaster'
import FloorMaster from './Component/FloorMaster'
import RoomMaster from './Component/RoomMaster'
import TableMaster from './Component/TableMaster'
import ItemMaster from './Component/ItemMaster'
import Logbook from './Component/Logbook'
import RoomTypeMaster from './Component/RoomTypeMaster'
import CheckIn from './Component/CheckIn'
import CheckOut from './Component/CheckOut'
import SaleBill from './Component/SaleBill'
import MessageBox from './Component/MessageBox'
import SaleBillTrash from './Component/SaleBillTrash'
import SaleBillCancel from './Component/SaleBillCancel'
import Test from './Component/Test'
import Reservation from './Component/Reservation'
import Settings from './Component/Settings'
import Rpt_Room_status from './Report/Rpt_Room_status'
import Rpt_CheckInReport from './Report/Rpt_CheckInReport'
import Rpt_BillReport from './Report/Rpt_BillReport'
import Company_Create from './Component/Company_Create'
import AccountMaster from './Component/AccountMaster'
import RoomService from './Component/RoomService'
import FoodOrder from './Component/FoodOrder'
import KotView from './Component/KotView'
import RestBillView from './Component/RestBillView'
import Rpt_TableStatus from './Report/Rpt_TableStatus'
import Rpt_SaleReport from './Report/Rpt_SaleReport'
import RestBillViewTrash from './Component/RestBillViewTrash'
import RestBillViewCancelled from './Component/RestBillViewCancelled'
import PrintKot from './Component/PrintKot'
import BillPaymentRest from './Component/BillPaymentRest'
import RecoveryyPassword from './Component/RecoveryyPassword'
import Passwordchange from './Component/Passwordchange'
import PrinterSelector from './Component/PrinterSelector'
import { Printdata } from './Component/Printdata'
import FabMas from './Component/FabMas'
import WasherMas from './Component/WasherMas'
import Purchase from './Component/Purchase'
import Rpt_PurchaseReport from './Report/Rpt_PurchaseReport.jsx'
import Cutting from './Component/Cutting.jsx'
import Rpt_CuttingReport from './Report/Rpt_CuttingReport.jsx'
import FabricatorEntry from './Component/FabricatorEntry.jsx'
import WasherEntry from './Component/WasherEntry.jsx'
import FinshingEntry from './Component/FinshingEntry.jsx'
import SaleEntry from './Component/SaleEntry.jsx'
import Rpt_FabricatorReport from './Report/Rpt_FabricatorReport.jsx'
import GodwnStk from './Component/GodwnStk.jsx'
import Rpt_WashReport from './Report/Rpt_WashReport.jsx'
import Rpt_FinalStock from './Report/Rpt_FinalStock.jsx'
import Rpt_FinishingReport from './Report/Rpt_FinishingReport.jsx'
import Party_master from './Component/Party_master.jsx'
import FindLot from './Component/FindLot.jsx'
import FinishingMas from './Component/FinishingMas.jsx'
import FindLotDetail from './Component/FindLotDetail.jsx'
import PurchaseGR from './Component/PurchaseGR.jsx'
import FinshingEntryOpening from './Component/FinshingEntryOpening.jsx'

import Rpt_PurchaseReport_GR from './Report/Rpt_PurchaseReport_GR.jsx'



const App = () => {
  
  return (
    <div id={css.app}>
          <Navbar/>
          <BrowserRouter>
            <Routes>
              <Route path='/' Component={Home}></Route>
              <Route path='/ItemUnit_View' Component={ItemUnit_View}></Route>
              <Route path='/ItemGroupMaster' Component={ItemGroupMaster}></Route>
              <Route path='/KitchenMaster' Component={KitchenMaster}></Route>
              <Route path='/DishTypeMaster' Component={DishTypeMaster}/>
              <Route path='/ItemCompanyMaster' Component={ItemCompanyMaster}/>
              <Route path='/DishHeadMaster' Component={DishHeadMaster}/>
              <Route path='/FloorMaster' Component={FloorMaster}/>
              <Route path='/RoomMaster' Component={RoomMaster}/>
              <Route path='/TableMaster' Component={TableMaster}/>
              <Route path='/ItemMaster' Component={ItemMaster}/>
              <Route path='/Logbook' Component={Logbook}/>
              <Route path='/RoomTypeMaster' Component={RoomTypeMaster}/>
              <Route path='/CheckIn' Component={CheckIn}/>
              <Route path='/CheckOut' Component={CheckOut}/>
              <Route path='/SaleBill' Component={SaleBill}/>
              <Route path='/MessageBox' Component={MessageBox}/>
              <Route path='/SaleBillTrash' Component={SaleBillTrash}/>
              <Route path='/SaleBillCancel' Component={SaleBillCancel}/>
              <Route path='/Test' Component={Test}/>
              <Route path='/Reservation' element={<Reservation/>}/>
              <Route path='/Settings' element={<Settings/>}/>
              <Route path='/Rpt_Room_status' element={<Rpt_Room_status/>}/>
              <Route path='/Rpt_CheckInReport' element={<Rpt_CheckInReport/>}/>
              <Route path='/Rpt_BillReport' element={<Rpt_BillReport/>}/>
              <Route path='/Company_Create' element={<Company_Create/>}/>
              <Route path='/AccountMaster' element={<AccountMaster/>}/>
              <Route path='/RoomService' element={<RoomService/>}/>
              <Route path='/FoodOrder' element={<FoodOrder/>}/>
              <Route path='/KotView' element={<KotView/>}/>
              <Route path='/RestBillView' element={<RestBillView/>}/>
              <Route path='/Rpt_TableStatus' element={<Rpt_TableStatus/>}/>
              <Route path='/Rpt_SaleReport' element={<Rpt_SaleReport/>}/>
              <Route path='/RestBillViewTrash' element={<RestBillViewTrash/>}/>
              <Route path='/RestBillViewCancelled' element={<RestBillViewCancelled/>}/>
              <Route path='/PrintKot' element={<PrintKot/>}/>
              <Route path='/BillPaymentRest' element={<BillPaymentRest/>}/>
              <Route path='/RecoveryyPassword' element={<RecoveryyPassword/>}/>
              <Route path='/Passwordchange' element={<Passwordchange/>}/>
              <Route path='/PrinterSelector' element={<PrinterSelector/>}/>
              <Route path='/Printdata' element={<Printdata/>}/>
              <Route path='/FabMas' element={<FabMas/>}/>
              <Route path='/WasherMas' element={<WasherMas/>}/>
              <Route path='/Purchase' element={<Purchase/>}/>
              <Route path='/Rpt_PurchaseReport' element={<Rpt_PurchaseReport/>}/>
              <Route path='/Cutting' element={<Cutting/>}/>
              <Route path='/Rpt_CuttingReport' element={<Rpt_CuttingReport/>}/>
              <Route path='/FabricatorEntry' element={<FabricatorEntry/>}/>
              <Route path='/WasherEntry' element={<WasherEntry/>}/>
              <Route path='/FinshingEntry' element={<FinshingEntry/>}/>
              <Route path='/SaleEntry' element={<SaleEntry/>}/>
              <Route path='/Rpt_FabricatorReport' element={<Rpt_FabricatorReport/>}/>
              <Route path='/GodwnStk' element={<GodwnStk/>}/>
              <Route path='/Rpt_WashReport' element={<Rpt_WashReport/>}/>
              <Route path='/Rpt_FinalStock' element={<Rpt_FinalStock/>}/>
              <Route path='/Rpt_FinishingReport' element={<Rpt_FinishingReport/>}/>
              <Route path='/Party_master' element={<Party_master/>}/>
              <Route path='/FindLot' element={<FindLot/>}/>
              <Route path='/FinishingMas' element={<FinishingMas/>}/>
              <Route path='/FindLotDetail' element={<FindLotDetail/>}/>
              <Route path='/PurchaseGR' element={<PurchaseGR/>}/>
              <Route path='/Rpt_PurchaseReport_GR' element={<Rpt_PurchaseReport_GR/>}/>
              <Route path='/FinshingEntryOpening' element={<FinshingEntryOpening/>}/>
                           
            </Routes>
          </BrowserRouter>     
          
    </div>
  )
}

export default App
