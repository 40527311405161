import axios from 'axios'
import React, { useEffect, useState } from 'react'
import hostlink from '../Hostlink/hostlink'
import { Link } from 'react-router-dom'
import DataTable from 'react-data-table-component'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import usercontrol from '../Hostlink/usercontrol'
import Modal from 'react-modal';
import './MessageBox.css'; // 
import Loading from './Loading'

const Checkin = () => {
    const [isLoading, setIsLoading] = useState(false);
    const usershopid = localStorage.getItem('MYPCKARTUSER') ? JSON.parse(localStorage.getItem('MYPCKARTUSER'))[0].id : '0';
    const today = new Date();
    const [AccCode, setAccCode] = useState([])
    const [frmdata, setfrmdata] = useState({
        fromdate: today.toISOString().split('T')[0],
        todate: today.toISOString().split('T')[0],
        status: 3
    });
    const { fromdate, todate, status } = frmdata;
    const [addroomtype, setaddroomtype] = useState(0);
    const [chtype, setchtype] = useState('')
    const [roomdetailA, setRoomDetailA] = useState([]);
    const [addroomcode, setaddroomcode] = useState(0);
    const [upstatusRoomcode, setupstatusRoomcode] = useState('')
    const [newItem, setNewItem] = useState({ chdate: '', rtype: 0, roomtypeview: '', roomnoview: '', rcode: 0, rent: 0, exbed: 0, exbedch: 0, chid: 0 });
    const [roomdetail, setroomdetail] = useState([]);
    const [logroom, setLogroom] = useState();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isModalOpenRoom, setIsModalOpenRoom] = useState(false);

    const handleDeleteItem = async (index) => {
        // Create a new array by filtering out the item at the specified index
        const updatedRoomDetails = roomdetail.filter((_, i) => i !== index);
        if (checkinstatus !== "") {
            await axios.delete(`${hostlink}/getchehinroomdelete/${checkinstatus}/${updatercode}`)
            await axios.post(`${hostlink}/room/updateroombulk/0/${updatercode}`)
            const rnameString = updatedRoomDetails.map(item => item.roomnoview).join(',');
            if (updatedRoomDetails.length === 0) {
                await axios.post(`${hostlink}/checkin/uprname/NA/${checkinstatus}`)
            } else {
                // return false
                await axios.post(`${hostlink}/checkin/uprname/${rnameString}/${checkinstatus}`)
            }
        }
        toast.success("Room deleted")
        // Update the state with the new array
        setroomdetail(updatedRoomDetails);
        handleCloseModalRoom();
    };
    const handleAddItem = () => {


        // if (chtype !== "Check-In") {
        //     toast.error("Bill Generated, You can't Add Room")
        //     return false
        // }
        if (document.getElementById("1inputnamechdate").value == "") {
            setIsLoading(false);
            toast.error("Please Select check in Date...")
            document.getElementById("1inputnamechdate").focus();
            return false
        }
        if (rtype == "0" || rtype === undefined) {
            setIsLoading(false);
            toast.error("Please Select Room Type...")
            document.getElementById("roomtype").focus();
            return false
        }
        if (rcode == "0" || rcode === undefined) {
            setIsLoading(false);
            toast.error("Please Select Room...")
            document.getElementById("roomcode").focus();
            return false
        }
        if (rent == "0" || rent == "00" || rent == "" || rcode === undefined) {
            setIsLoading(false);
            toast.error("Please Enter Room Rent...")
            document.getElementById("roomrent").focus();
            return false
        }

        const isRCodeExists = roomdetail.some(item => item.rcode === rcode);
        if (isRCodeExists) {
            setIsLoading(false);
            toast.error("Room Name Already exists...");
            return false;
        }

        if (chtype == "Check-In") {
            axios.post(`${hostlink}/getchehinroom`, [{ chdate: indatecheckin, rtype: rtype, rcode: rcode, rent: rent, roomtypeview: document.getElementById("roomtype").selectedOptions[0].text, roomnoview: addroomcode, exbed: exbed, exbedch: exbedch, chid: id }])
            axios.post(`${hostlink}/room/updateroombulk/1/${rcode}`)
            var x = ([...roomdetail, { chdate: indatecheckin, rtype: rtype, rcode: rcode, rent: rent, roomtypeview: document.getElementById("roomtype").selectedOptions[0].text, roomnoview: addroomcode, exbed: exbed, exbedch: exbedch, chid: 0 }])
            const rnameString = x.map(item => item.roomnoview).join(',');
            axios.post(`${hostlink}/checkin/uprname/${rnameString}/${id}`)
        }
        setroomdetail([...roomdetail, { chdate: indatecheckin, rtype: rtype, rcode: rcode, rent: rent, roomtypeview: document.getElementById("roomtype").selectedOptions[0].text, roomnoview: addroomcode, exbed: exbed, exbedch: exbedch, chid: 0 }])
        document.getElementById("11111inputname").focus();
    };


    const [messagedata, setMessagedata] = useState({
        deleteid: "",
        deletemsg: "",
        updatercode: "",
        checkinstatus: ""
    })
    const { deleteid, deletemsg, updatercode, checkinstatus } = messagedata;


    const handleOpenModalRoom = (index, rcode, roomnoview, chid) => {
        setMessagedata({ ...messagedata, deleteid: index, deletemsg: roomnoview, updatercode: rcode, checkinstatus: chid })
        setIsModalOpenRoom(true);
    };

    const handleOpenModal = (e, flname, uprcode, checkinstatus) => {
        setMessagedata({ ...messagedata, deleteid: e, deletemsg: flname, updatercode: uprcode, checkinstatus: checkinstatus })
        setIsModalOpen(true);
    };
    const handleCloseModal = () => { setIsModalOpen(false); };
    const handleCloseModalRoom = () => { setIsModalOpenRoom(false); };
    const handleNoClick = () => { console.log('User clicked No'); handleCloseModal(); };
    const handleNoClickRoom = () => { console.log('User clicked No'); handleCloseModalRoom(); };
    const [refreshData, setRefreshData] = useState(false)
    const api = "/checkin";
    const [RoomTYpe, setRoomTYpe] = useState([])
    const [Room, setRoom] = useState([])
    const [pagemode, setPagemode] = useState(0)
    const [data, setData] = useState([])
    const Closeform = () => {
        window.location.assign("/CheckIn")
    }
    const [logdetail, setlogdetail] = useState({
        onform: "CheckIN",
        operation: "",
        newdesc: "",
        olddesc: "",
        shopid: usershopid,
        usercode: usercontrol.user_code,
        computername: usercontrol.user_computer
    })
    const getrent = (e, addroomtype) => {
        setaddroomcode(addroomtype)
        axios.get(`${hostlink}/room/${e}`)
            .then((Response) => {
                document.getElementById("extrabadchrge").value = Response.data.exrent
                document.getElementById("roomrent").value = Response.data.rent
                if (document.getElementById("resvno").value !== "") {
                    var ld = { ...savedata, exbedch: Response.data.exrent }
                } else {
                    var ld = { ...savedata, rent: Response.data.rent, exbedch: Response.data.exrent }
                }
                setSavedata(ld);
                setLogroom(Response.data.rname)
            })
            .catch((Response) => { "data Not Found" })
    }
    const getroombyid = (e, addroomtype) => {
        setaddroomtype(addroomtype)
        axios.get(`${hostlink}/room/getnamebyid/${e}`)
            .then((Response) => {
                setRoom(Response.data)
            })
            .catch((Response) => { "data Not Found" })
    }
    const saveLog = (ld) => {
        axios.post(`${hostlink}/logbook`, ld)
    }
    const searchData = (e) => {
        setRefreshData(true)
    }

    const SaveData = async (e) => {
        
        setIsLoading(true);
        const myPckartUser = localStorage.getItem('MYPCKARTUSER'); if (!myPckartUser) { toast.error("Please Login First.."); return false };
        const rnameString = roomdetail.map(item => item.roomnoview).join(',');
        const rcodeString = roomdetail.map(item => item.rcode).join(',');
        var SaveBillDataupdated = { ...savedata, roomdescription: rnameString, roomdescriptioncode: rcodeString, narration: usershopid + document.getElementById("1inputname").value.trim() + document.getElementById("inputname").value.trim() }
        const inputValue = document.getElementById("inputname").value.trim();
        if (inputValue === "") {
            setIsLoading(false);
            toast.error("Please Enter Mobile No")
            document.getElementById("inputname").focus()
            return false
        }
        const inputValue1 = document.getElementById("inputname1").value.trim();
        if (inputValue1 === "") {
            setIsLoading(false);
            toast.error("Please Enter Guest Name")
            document.getElementById("inputname1").focus()
            return false
        }

        if (roomdetail.length === 0) {
            setIsLoading(false);
            toast.error("No room details available. Add Room details.")
            return false
        }
        try {
            if (document.getElementById("pagemode").innerHTML == ' Check-IN [ NEW ]') {

                await axios.post(`${hostlink}${api}`, SaveBillDataupdated)
                    .then((Response) => {
                        setIsLoading(false);
                        toast("Save Sucessfull")
                        console.log(Response.data)
                        var chidd = Response.data
                        var idd = roomdetail.map(room => ({
                            ...room,
                            chid: chidd, shopid: usershopid
                        }));
                        setroomdetail(idd);
                        axios.post(`${hostlink}/getchehinroom`, idd)
                        const rcodeString = roomdetail.map(item => item.rcode).join(',');
                        setupstatusRoomcode(rcodeString);
                        axios.post(`${hostlink}/room/updateroombulk/1/${rcodeString}`)
                        var ld = {
                            ...logdetail,
                            newdesc: `Mob No : ${mobno}, Name : ${gname}, Room : ${rnameString}, Advance : ${advance}`,
                            operation: "New"
                        }
                        setlogdetail(ld);
                        saveLog(ld)
                        setTimeout(() => {
                            Closeform()
                            window.location.assign("/CheckIn")
                        }, 1000);
                    })
                    .catch(() => {
                        setIsLoading(false);
                        toast.error("Checkin already exists On this Mobile No for this Date.")
                        document.getElementById("cmdsave").disabled = false
                    })
            } else {
                await axios.put(`${hostlink}${api}`, SaveBillDataupdated)
                    .then((Response) => {
                        setIsLoading(false);
                        toast("Save Sucessfull")
                        setTimeout(() => {
                            var ld = {
                                ...logdetail,
                                newdesc: `Mob No : ${mobno}, Name : ${gname}, Room : ${rnameString}, Advance : ${advance}`,
                                operation: "Edit"
                            }
                            setlogdetail(ld);
                            saveLog(ld)
                            window.location.assign("/CheckIn")
                        }, 1000);
                    })
                    .catch(() => {
                        setIsLoading(false);
                        toast.error("Name already exists.")
                        document.getElementById("cmdsave").disabled = false
                    })
            }
        } catch (error) {
        }
    }
    const [savedata, setSavedata] = useState({
        id: "",
        indate: today.toISOString().split('T')[0],
        mobno: "",
        reference:"",
        nationality: "Indian",
        gname: "",
        add1: "",
        add2: "",
        documentno: "",
        documentpath: "",
        email: "",
        gstno: "",
        cname: "",
        rtype: 0,
        rcode: 0,
        rent: "",
        exbed: "",
        exbedch: "",
        mg: "",
        fg: "",
        cg: "",
        stday: "",
        discount: "",
        advance: "",
        paidby: "",
        remarks: "",
        resvno: "",
        roomdescription: "",
        statusview: 0,
        indatecheckin: today.toISOString().split('T')[0],
        roomdescriptioncode: "",
        shopid: usershopid,
        narration: "",
        acccode: 0
    })

    const { id, mobno, roomdescription, roomdescriptioncode, gname, add1, add2, documentno, statusview, documentpath, indate, email, nationality, gstno, indatecheckin, cname, rcode, rtype, rent, exbed, exbedch, mg, fg, cg, acccode, stday, discount, advance, paidby, reference,remarks, resvno } = savedata;
    const onChangevalue = (e) => {
        const { name, value } = e.target;
        const sanitizedValue = value.replace(/['"!`#$%^&*+{}|;:<>?=]/g, '');
        setSavedata({ ...savedata, [name]: sanitizedValue });
    }
    const onChangevalueformdate = (e) => {
        const { name, value } = e.target;
        const sanitizedValue = value.replace(/['"!`@#$%^&*+{}|;:,.<>?=]/g, '');
        setfrmdata({ ...frmdata, [name]: sanitizedValue });
    };
    const cmdEdit = async (e, type) => {
        setIsLoading(true);
        document.getElementById("resvno").disabled = true
        if (type == "Billed") {
            toast.error(`Can't Edit. Bill Generated..`)
            setIsLoading(false);
            return false
        }
        setPagemode(1);
        setchtype(type);

        await axios.get(`${hostlink}${api}/${e}`)
            .then((Response) => {
                document.getElementById("pagemode").innerHTML = " Check-IN [ EDIT ]"
                let x = document.getElementById("frm_controll_Add_Edit")
                var roomname;
                x.style.display = "block"
                document.getElementById("inputname").focus();
                document.getElementById("cmdnew").disabled = true
                let y = document.getElementById("dataviewgrid")
                y.style.display = "none"

                setSavedata(Response.data);
                setIsLoading(false);
                var ld = {
                    ...logdetail,
                    olddesc: `Mob No : ${Response.data.mobno}, Name : ${Response.data.gname}, Room : ${Response.data.roomdescription}, Advance : ${Response.data.advance}`,
                    operation: "Edit"
                }
                setlogdetail(ld);
                console.log(`${hostlink}/getchehinroomDetail/${e}`)
                axios.get(`${hostlink}/getchehinroomDetail/${e}`)
                    .then((resp) => {
                        setroomdetail(resp.data)
                    })

            })
            .catch((res) => { console.log("Data Not find") })
    }

    const cmdNew = () => {
        setPagemode(0);
        console.log(rtype);
        let y = document.getElementById("dataviewgrid")
        y.style.display = "none"

        setchtype("new");
        document.getElementById("pagemode").innerHTML = " Check-IN [ NEW ]"
        let x = document.getElementById("frm_controll_Add_Edit")
        x.style.display = "block"
        document.getElementById("frm_controll_Add_Edit")
        document.getElementById("resvno").focus();
        document.getElementById("inputname").value = '';
        document.getElementById("cmdsave").disabled = false
        document.getElementById("resvno").disabled = false

    }
    const deleteData = async (e, flname) => {

        try {
            await axios.get(`${hostlink}${api}/${e}`)
                .then((Responsenew) => {

                    var ld = {
                        ...logdetail,
                        newdesc: `Mob No : ${Responsenew.data.mobno}, Name : ${Responsenew.data.gname}, Room : ${deletemsg}, Advance : ${Responsenew.data.advance}`,
                        olddesc: "",
                        operation: "Delete"
                    }
                    setlogdetail(ld);
                    saveLog(ld)
                    return false
                })

            if (checkinstatus === "Check-In") {
                axios.post(`${hostlink}/room/updateroombulk/0/${updatercode}`)
            }
            await axios.delete(`${hostlink}/getchehinroomdeletechdetail/${e}`)
            await axios.delete(`${hostlink}${api}/${e}`)
            toast.success("Delete Sucessfull")
            setInterval(() => {
                handleCloseModal();
                window.location.assign("/Checkin")

            }, 1000);
        } catch (error) { }
    }

    const getResvData = async (e) => {
        try {
            if (e.target.value !== "") {
                // Check Reservation
                const nameQuery = `select * from checkinmas where resvno = ${e.target.value} and shopid = ${usershopid} limit 1`;
                const nameResponse = await axios.get(`${hostlink}/api/executeQuery?sqlQuery=${encodeURIComponent(nameQuery)}`);
                if (nameResponse.data.length > 0) {
                    toast.error(`Reservation No : ${e.target.value} already checked In...`);
                    var id = {
                        ...savedata,
                        mobno: "", resvno: "", gname: "", add1: "", add2: "", email: "", rtype: 0, advance: "", discount: "", mg: "", fg: "", cg: "", stday: "", remarks: "", cname: "", paidby: "", gstno: "", documentno: ""
                    };
                    setSavedata(id); getroombyid(0);
                    document.getElementById("cmdsave").disabled = false; setIsLoading(false); document.getElementById("inputnameitem").focus();
                    return false;
                }

                await axios.get(`${hostlink}/${usershopid}/reservation/${e.target.value}`)
                    .then((resp) => {
                        if (resp.data.length === 0) {
                            toast.error(`Reservation No : ${e.target.value} Not Found`)
                            var id = {
                                ...savedata,
                                mobno: "", resvno: "", gname: "", add1: "", add2: "", email: "", rtype: 0, advance: "", discount: "", mg: "", fg: "", cg: "", stday: "", remarks: "", cname: "", paidby: "", gstno: "", documentno: ""
                            };
                            setSavedata(id); getroombyid(0);
                            return false
                        }
                        if (resp.data.chdate !== today.toISOString().split('T')[0]) {
                            toast.error(`Check In Date is : ${resp.data.chdate ? new Date(resp.data.chdate).toLocaleDateString('en-GB', { day: '2-digit', month: '2-digit', year: '2-digit' }).replace(/\//g, '/') : "-"}. Today Date is : ${today.toISOString().split('T')[0] ? new Date(today.toISOString().split('T')[0]).toLocaleDateString('en-GB', { day: '2-digit', month: '2-digit', year: '2-digit' }).replace(/\//g, '/') : "-"}`)
                            var id = {
                                ...savedata,
                                mobno: "", resvno: "", gname: "", add1: "", add2: "", email: "", rtype: 0, advance: "", discount: "", mg: "", fg: "", cg: "", stday: "", remarks: "", cname: "", paidby: "", gstno: "", documentno: ""
                            };
                            setSavedata(id); getroombyid(0);
                            return false
                        }
                        var id = {
                            ...savedata,
                            mobno: resp.data.gmob, indatecheckin: resp.data.chdate, gname: resp.data.gname, acccode: resp.data.acccode, rent: resp.data.rent, add1: resp.data.add1, add2: resp.data.add2, email: resp.data.email, rtype: resp.data.rtype, advance: resp.data.advance, discount: resp.data.discount, mg: resp.data.mg, fg: resp.data.fg, cg: resp.data.cg, stday: resp.data.stday, remarks: resp.data.remarks, cname: resp.data.cname, paidby: resp.data.paidby, gstno: resp.data.gstno, documentno: resp.data.documentno
                        };
                        setSavedata(id)
                        getroombyid(resp.data.rtype)
                        toast.success("Data Found")
                    })
            }
        } catch (error) {
        }
    }
    const handleNationalityChange = (event) => {
        const selectedNationality = event.target.value;
        setSavedata({
          ...savedata,
          nationality: selectedNationality
        });
      };
    useEffect(() => {
        let x = document.getElementById("frm_controll_Add_Edit")
        x.style.display = "none"
        let y = document.getElementById("addroomNew")
        y.style.display = "block"
        document.getElementById("cmdnew").disabled = false
        document.getElementById("cmdsave").disabled = false
        axios.get(`${hostlink}/${usershopid}/rptcheckin/${fromdate}/${todate}/${statusview}`)
            .then((Response) => {
                setData(Response.data)
                setRefreshData(false)

                axios.get(`${hostlink}/${usershopid}/rtmas`)
                    .then((Response) => {
                        setRoomTYpe(Response.data)
                        const firstrtcode = Response.data[0];

                        axios.get(`${hostlink}/room/getnamebyid/${firstrtcode.id}`)
                            .then((Response) => {
                                setRoom(Response.data)
                                const firstroomcode = Response.data[0];
                                axios.get(`${hostlink}/${usershopid}/accmas`)
                                    .then((Response) => {
                                        setAccCode(Response.data)
                                        const firstAccode = Response.data[0];
                                        setaddroomcode(firstroomcode.rname)
                                        axios.get(`${hostlink}/room/${firstroomcode.id}`)
                                            .then((Response) => {
                                                document.getElementById("extrabadchrge").value = Response.data.exrent
                                                document.getElementById("roomrent").value = Response.data.rent
                                                if (document.getElementById("resvno").value !== "") {
                                                    var ld = { ...savedata, exbedch: Response.data.exrent, rtype: firstrtcode ? firstrtcode.id : null, acccode: firstAccode ? firstAccode.id : null, rcode: firstroomcode ? firstroomcode.id : null }
                                                } else {
                                                    var ld = { ...savedata, rent: Response.data.rent, exbedch: Response.data.exrent, rtype: firstrtcode ? firstrtcode.id : null, acccode: firstAccode ? firstAccode.id : null, rcode: firstroomcode ? firstroomcode.id : null }
                                                }
                                                setSavedata(ld);
                                                setLogroom(Response.data.rname)
                                            })
                                            .catch((Response) => { "data Not Found" })
                                    })
                                    .catch((Response) => { "data Not Found" })
                            })
                            .catch((Response) => { "data Not Found" })
                    })
                    .catch((Response) => { "data Not Found" })
            })
            .catch((Response) => {
                console.log("Data Not Fatched")
            })

    }, [refreshData])
    return (
        <div className='p-1'>
            <div id='frm_controll_Add_Edit' className='container shadow rounded' style={{ display: "none" }} ><br />
                <div className='row'>
                    <div className='col-md-7'>
                        <h5 className='font-monospace text-primary'> <i class="fa-solid fa-door-open"></i> <span id='pagemode' > Check-IN [ New ]</span> </h5>
                    </div>
                    <div className='col-md-1 text-end'>
                        <label className='' style={{ color: "red" }} class="form-label">Reg No : </label>
                    </div>
                    <div className='col-md-1 text-end'>
                        <input type="text" class="form-control" onChange={(e) => { onChangevalue(e) }} onBlur={(e) => { getResvData(e) }} name='resvno' value={resvno} id="resvno" /> <br />
                    </div>
                    <div className='col-md-1  text-end'>
                        <label className='' class="form-label">Date :</label>
                    </div>
                    <div className="col-md-2">
                        <input type="date" class="form-control" onChange={(e) => { onChangevalue(e) }} name='indate' value={indate} id="1inputname" /> <br />
                    </div>
                </div>
                <div className='row'>
                    <div class="col-md-2">
                        <label className='font-monospace' class="form-label">Mob No</label>
                        <input type="tel" onChange={(e) => { onChangevalue(e) }} pattern="[0-9]*" title="Please enter a 10-digit mobile number" required maxLength="10" onBlur={(e) => { const inputValue = e.target.value; if (inputValue.length < 10) { toast.error("Please enter valid mobile number"); e.target.focus(); } }} onKeyPress={(e) => { if (e.target.value.length >= 10 || isNaN(Number(e.key))) { e.preventDefault(); } }} name='mobno' value={mobno} class="form-control" id="inputname" />
                    </div>
                    <div class="col-md-4">
                        <label className='font-monospace' class="form-label">Guest Name</label>
                        <input type="text" onChange={(e) => { onChangevalue(e) }} name='gname' value={gname} class="form-control" id="inputname1" /> <br />
                    </div>
                    <div class="col-md-3">
                        <label className='font-monospace' class="form-label">Add 1</label>
                        <input type="text" onChange={(e) => { onChangevalue(e) }} name='add1' value={add1} class="form-control" id="inputname11" /> <br />
                    </div>
                    <div class="col-md-3">
                        <label className='font-monospace' class="form-label">Add 2</label>
                        <input type="text" onChange={(e) => { onChangevalue(e) }} name='add2' value={add2} class="form-control" id="inputname111" /> <br />
                    </div>
                    <div className="col-md-2">
                        <label className='font-monospace' class="form-label">Document No</label>
                        <input type="text" onChange={(e) => { onChangevalue(e) }} name='documentno' value={documentno} class="form-control" id="inputname1111" /> <br />
                    </div>
                    <div className="col-md-4">
                        <label className='font-monospace' class="form-label">Select Document</label>
                        <input type="file" class="form-control" id="documentpath" />
                    </div>
                    <div className="col-md-3">
                        <label className='font-monospace' class="form-label">Email</label>
                        <input type="text" onChange={(e) => { onChangevalue(e) }} name='email' value={email} class="form-control" id="inputname11111" /> <br />
                    </div>
                    <div className="col-md-3">
                        <label className='font-monospace' class="form-label">GST No</label>
                        <input type="text" onChange={(e) => { onChangevalue(e) }} name='gstno' value={gstno} class="form-control" id="inputname111111" /> <br />
                    </div>
                    <div className="col-md-3">
                        <label className='font-monospace' class="form-label">Company Name</label>
                        <input type="text" onChange={(e) => { onChangevalue(e) }} name='cname' value={cname} class="form-control" id="inputname1111111" /> <br />
                    </div>

                    <div className="col-md-3">
                        <label className='font-monospace' class="form-label">Nationality</label>
                        <select
                            id="nationality"
                            name="nationality"
                            value={nationality || savedata.nationality}
                            onChange={handleNationalityChange}
                            className="form-control">
                            <option value="indian">Indian</option>
                            <option value="foreigner">Foreigner</option>
                        </select><br />
                    </div>
                    <div className="col-md-3">
                        <label className='font-monospace' class="form-label">Reference by</label>
                        <input type="text" onChange={(e) => { onChangevalue(e) }} name='reference' value={reference} class="form-control" id="12inputname" /> <br />
                    </div>
                    {/*- Stay Details -------------------------------------*/}
                    <h5 className='font-monospace text-primary'> <i class="fa-solid fa-circle-info"></i> <span > Stay Detail</span></h5>

                    <div className="col-md-2">
                        <label className='font-monospace' class="form-label">Check In Date</label>
                        <input type="date" class="form-control" onChange={(e) => { onChangevalue(e) }} name='indatecheckin' value={indatecheckin} id="1inputnamechdate" /> <br />
                    </div>

                    <div class="col-md-2">
                        <label for="inputState" class="form-label">Room Type</label>
                        <select id="roomtype" onChange={(e) => { onChangevalue(e); getroombyid(e.target.value, e.target.selectedOptions[0].text); }} name='rtype' value={rtype} class="form-select">
                            {/* <option selected key={0} value={0}>Choose...</option> */}
                            {RoomTYpe.map((x) => {
                                return (
                                    <option key={x.id} value={x.id} title={x.rname}> {x.rtname} </option>
                                )
                            })}
                        </select>
                    </div>

                    <div class="col-md-2">
                        <label for="inputState" class="form-label">Room</label>
                        <select id="roomcode" onChange={(e) => { onChangevalue(e) }} onMouseOut={(e) => { getrent(e.target.value, e.target.selectedOptions[0].text) }} name='rcode' value={rcode} class="form-select">
                            {/* <option selected key={0} value={0}>...</option> */}
                            {Room.map((x) => {
                                return (
                                    <option key={x.id} value={x.id} title={x.rname}> {x.rname} </option>
                                )
                            })}
                        </select>
                    </div>
                    <div className="col-md-2">
                        <label className='font-monospace' class="form-label">Rent</label>
                        <input type="number" onChange={(e) => { onChangevalue(e) }} name='rent' value={rent} class="form-control" id="roomrent" /> <br />
                    </div>
                    <div className="col-md-1">
                        <label className='font-monospace' class="form-label">Ext Bed</label>
                        <input type="number" onChange={(e) => { onChangevalue(e) }} name='exbed' value={exbed} class="form-control" id="111inputname" /> <br />

                    </div>
                    <div className="col-md-2">
                        <label className='font-monospace' class="form-label">Bed Charge</label>
                        <input type="number" onChange={(e) => { onChangevalue(e) }} name='exbedch' value={exbedch} class="form-control" id="extrabadchrge" /> <br />
                    </div>

                    <div id='addroomNew' className="col-md-1 text-center">
                        <label className='font-monospace ' style={{ color: "white" }} class="form-label">-</label> <br />
                        <button style={{ backgroundImage: 'linear-gradient(to right, #ff9999, #ff9999, #ff9999)', color: 'black', fontSize: "12px", width: "90%", textAlign: "center", justifyContent: "center" }} onClick={() => { handleAddItem() }} type="submit" class="btn btn-info"><i class="fa-solid fa-circle-plus"></i> Add</button>
                    </div>


                    <div className='show-on-mobile row bg-info p-1 m-0 container shadow rounded align-content-end border border-1' style={{ backgroundImage: 'linear-gradient(to right, #6AF4D7, #ff9999, #6AF4D7)', color: 'black', padding: '20px' }}>
                        {roomdetail.map((resp, index) => (
                            <div key={index} className='row text-center align-self-center p-1 m-0 bg-light border border-1'>
                                <h6 style={{ "color": "blue" }}>Room : {resp.roomnoview}</h6>
                                <div className='col-9 text-start' style={{ fontSize: "15px", color: "black" }}>
                                    <table style={{ "width": "100%" }}>
                                        <tr><td>Check In date &nbsp;</td><td>: {resp.chdate ? new Date(resp.chdate).toLocaleDateString('en-GB', { day: '2-digit', month: '2-digit', year: '2-digit' }).replace(/\//g, '/') : "-"}</td></tr>
                                        <tr><td>Room Type</td><td>: {resp.roomtypeview}</td></tr>
                                        <tr><td>Rent</td><td>: {resp.rent}</td></tr>
                                        <tr><td>Extra bed</td><td>: {resp.exbed}</td></tr>
                                        <tr><td>Bed Charge</td><td>: {resp.exbedch}</td></tr>
                                    </table>
                                </div>
                                <div className='col-3 align-self-center'>
                                    <button onClick={() => handleOpenModalRoom(index, resp.rcode, resp.roomnoview, resp.chid)} type="submit" className="btn btn-outline-danger"> <i className="fa-solid fa-trash"></i></button>
                                </div>
                                <Modal isOpen={isModalOpenRoom} onRequestClose={handleCloseModalRoom} contentLabel="Delete Confirmation" className="modal-content" overlayClassName="modal-overlay">
                                    <div><p>Are you sure you want to delete this <br /> Room No : {deletemsg} ?</p><div className="button-container"><button className="yes-button" onClick={(e) => { handleDeleteItem(deleteid) }}>Yes</button> <button className="no-button" onClick={handleNoClickRoom}>No</button></div></div>
                                </Modal>
                            </div>
                        ))}
                    </div>

                    <div className='hide-on-mobile row bg-info p-1 m-0 container shadow rounded align-content-end border border-1' style={{ backgroundImage: 'linear-gradient(to right, #6AF4D7, #ff9999, #6AF4D7)', color: 'black', padding: '20px' }}>
                        <div className='row text-center align-self-center'>
                            <div className='col-md-2'>Check In Date</div>
                            <div className='col-md-2'>Room Type</div>
                            <div className='col-md-2'>Room</div>
                            <div className='col-md-2'>Rent</div>
                            <div className='col-md-1'>Extra Bed</div>
                            <div className='col-md-2'>Bed Charge</div>
                            <div className='col-md-1'>Action</div>
                        </div>
                        {roomdetail.map((resp, index) => (
                            <div key={index} className='row text-center align-self-center p-1 m-0 bg-light border border-1'>
                                <div className='col-md-2 align-self-center'>{resp.chdate ? new Date(resp.chdate).toLocaleDateString('en-GB', { day: '2-digit', month: '2-digit', year: '2-digit' }).replace(/\//g, '/') : "-"}</div>
                                <div className='col-md-2 align-self-center'>{resp.roomtypeview}</div>
                                <div className='col-md-2 align-self-center'>{resp.roomnoview}</div>
                                <div className='col-md-2 align-self-center'>{resp.rent}</div>
                                <div className='col-md-1 align-self-center'>{resp.exbed}</div>
                                <div className='col-md-2 align-self-center'>{resp.exbedch}</div>
                                <div className='col-md-1 align-self-center'>
                                    <button onClick={() => handleOpenModalRoom(index, resp.rcode, resp.roomnoview, resp.chid)} type="submit" className="btn btn-outline-danger"> <i className="fa-solid fa-trash"></i></button>
                                    {/* <button onClick={() => handleDeleteItem(index)} type="submit" className="btn btn-outline-danger"> <i className="fa-solid fa-trash"></i></button> */}
                                </div>

                                <Modal isOpen={isModalOpenRoom} onRequestClose={handleCloseModalRoom} contentLabel="Delete Confirmation" className="modal-content" overlayClassName="modal-overlay">
                                    <div><p>Are you sure you want to delete this <br /> Room No : {deletemsg} ?</p><div className="button-container"><button className="yes-button" onClick={(e) => { handleDeleteItem(deleteid) }}>Yes</button> <button className="no-button" onClick={handleNoClickRoom}>No</button></div></div>
                                </Modal>
                            </div>
                        ))}
                    </div>


                    <div className="col-md-1">
                        <label className='font-monospace' class="form-label">Male</label>
                        <input type="number" onChange={(e) => { onChangevalue(e) }} name='mg' value={mg} class="form-control" id="11111inputname" /> <br />
                    </div>

                    <div className="col-md-1">
                        <label className='font-monospace' class="form-label">Female</label>
                        <input type="number" onChange={(e) => { onChangevalue(e) }} name='fg' value={fg} class="form-control" id="111111inputname" /> <br />
                    </div>

                    <div className="col-md-1">
                        <label className='font-monospace' class="form-label">Child</label>
                        <input type="number" onChange={(e) => { onChangevalue(e) }} name='cg' value={cg} class="form-control" id="2inputname" /> <br />
                    </div>

                    <div className="col-md-1">
                        <label className='font-monospace' class="form-label">Stay Days</label>
                        <input type="number" onChange={(e) => { onChangevalue(e) }} name='stday' value={stday} class="form-control" id="22inputname" /> <br />
                    </div>
                    <div className="col-md-1">
                        <label className='font-monospace' class="form-label">Dis %</label>
                        <input type="number" onChange={(e) => { onChangevalue(e) }} name='discount' value={discount} class="form-control" id="222inputname" /> <br />
                    </div>

                    <div className="col-md-1">
                        <label className='font-monospace' class="form-label">Advance</label>
                        <input type="number" onChange={(e) => { onChangevalue(e) }} name='advance' value={advance} class="form-control" id="2222inputname" /> <br />
                    </div>

                    <div class="col-md-2">
                        <label for="inputState" class="form-label">Paid By</label>
                        <select id="acccode" onChange={(e) => { onChangevalue(e) }} name='acccode' value={acccode} class="form-select">
                            {/* <option selected key={0} value={0}>Choose...</option> */}
                            {AccCode.map((x) => {
                                return (
                                    <option key={x.id} value={x.id}> {x.accname} </option>
                                )
                            })}
                        </select>
                    </div>

                    
                    <div className="col-md-4">
                        <label className='font-monospace' class="form-label">Remarks</label>
                        <input type="text" onChange={(e) => { onChangevalue(e) }} name='remarks' value={remarks} class="form-control" id="12inputname" /> <br />
                    </div>
                </div>


                <div class="col-md-12 text-end">
                    <button onClick={(e) => (SaveData(e))} id='cmdsave' class="btn btn-primary">Save</button>  &nbsp;
                    <button onClick={Closeform} type="submit" class="btn btn-primary">Cancel</button>
                    <br />
                    <br />
                </div>
                {/* </form> */}
            </div>
            {/* ----------------------- */}
            <div className='container p-2' id='dataviewgrid'>
                <div className='row p-1'>
                    <div className='col-md-2 p-1'>
                        <h5 className='font-monospace'><i class="fa-solid fa-door-open"></i> Check-IN</h5>
                    </div>
                    <div className='col-md-2 rounded' style={{ display: "flex", alignItems: "center", background: "rgb(245,245,246)", height: "40px" }}>
                        <i class="fa-solid fa-magnifying-glass " ></i> &nbsp;&nbsp; <input type="text" class="" onChange={(e) => { searchData(e) }} name="search" id="search" placeholder="Search" style={{ background: "rgb(245,245,246)", outline: "none", border: "0", width: "100%" }} /> <br />
                    </div>

                    <div className='col-md-6 align-self-end' style={{ display: "flex", flexDirection: "row", justifyContent: "center" }}>
                        <span className="f-size-on-mobile align-self-end" style={{ verticalAlign: 'middle', height: '100%', }}>
                            <label htmlFor="todate" className="form-label text-center">From : &nbsp;</label>
                        </span>
                        <span> <input type="date" id="fromdate" name="fromdate" onChange={(e) => { onChangevalue(e); onChangevalueformdate(e); searchData(e); }} value={fromdate} className="form-control" /></span>


                        <span className="f-size-on-mobile align-self-end">
                            <label htmlFor="todate" className="form-label text-center">&nbsp; To : &nbsp;</label>
                        </span>
                        <span>
                            <input type="date" id="todate" name="todate" onChange={(e) => { onChangevalue(e); onChangevalueformdate(e); searchData(e); }} value={todate} className="form-control" />
                        </span>
                    </div>

                    {/* <div className='col-md-2'>
                        <select id="roomtype" onChange={(e) => { onChangevalue(e); searchData(e); }} name='statusview' value={statusview} className="form-select" >
                            <option value={3}>All</option>
                            <option value={0}>Check-In</option>
                            <option value={1}>Check-Out</option>
                            <option value={2}>Billed</option>
                        </select>
                    </div> */}

                    <div className='col-md-2 text-end'>
                        <button id='cmdnew' onClick={cmdNew} className='btn btn-outline-primary'> <i class="fa-solid fa-plus"></i> &nbsp;Check-In </button>
                    </div>
                    <div style={{ maxHeight: "100vh", overflow: "auto" }} className='col-12 '>
                        <table className="table table-hover table-striped" style={{}}>
                            <thead>
                                <tr>
                                    <th scope="col" className="f-size-on-mobile">SNo</th>
                                    <th scope="col" className="f-size-on-mobile">Check-in <span className="detail-on-mobile"> Details</span></th>
                                    <th scope="col" className="hide-on-mobile">Check-out</th>
                                    <th scope="col" className="hide-on-mobile">Time</th>
                                    <th scope="col" className="hide-on-mobile">Room</th>
                                    <th scope="col" className="hide-on-mobile">Status</th>
                                    <th scope="col" className="hide-on-mobile">Guest Name</th>
                                    <th scope="col" className="hide-on-mobile">Guest Mob</th>
                                    <th scope="col" className="hide-on-mobile">Guest Add</th>
                                    <th scope="col" className="f-size-on-mobile">Action</th>
                                </tr>
                            </thead>
                            <tbody>

                                {data.map((res, x) => (

                                    <tr style={{ verticalAlign: 'middle', height: '100%' }}><th scope="row" className="f-size-on-mobile">{x + 1}</th>
                                        <td className="f-size-on-mobile" style={{ wordWrap: "break-word" }}>
                                            <span className="detail-on-mobile"><b> Check in Date : {res[0].indate ? new Date(res[0].indate).toLocaleDateString('en-GB', { day: '2-digit', month: '2-digit', year: '2-digit' }).replace(/\//g, '/') : "-"}</b> </span>
                                            <span className='hide-on-mobile'> {res[0].indate ? new Date(res[0].indate).toLocaleDateString('en-GB', { day: '2-digit', month: '2-digit', year: '2-digit' }).replace(/\//g, '/') : "No data"}</span> <br />
                                            <div className="detail-on-mobile" style={{ fontSize: "12px", color: "blue" }}>
                                                Guest : {res[0].gname} <br /> Mob : {res[0].mobno} <br /> Room : {res[0].roomdescription} <br /> Advance : {res[0].advance}, Status : {res[res.length - 1]} <br />  <span style={{ color: "blue" }}> <b> Checkout Date : {res[0].indate ? new Date(new Date(res[0].indate).getTime() + ((res[0].stday) * 24 * 60 * 60 * 1000)).toLocaleDateString('en-GB', { day: '2-digit', month: '2-digit', year: '2-digit' }).replace(/\//g, '/') : "No data"}</b> </span> <br />
                                                <span style={{ color: "red" }}> <b> Bill Date : {res[0].outdate ? new Date(res[0].outdate).toLocaleDateString('en-GB', { day: '2-digit', month: '2-digit', year: '2-digit' }).replace(/\//g, '/') : "Pending Bill"}, Bill No :  {res[0].blno}  </b> </span>
                                            </div>
                                        </td>
                                        <td className="hide-on-mobile" style={{ wordWrap: "break-word" }}>{(res[0].indate ? new Date(new Date(res[0].indate).getTime() + Math.max(Number(res[0].stday), 1) * 24 * 60 * 60 * 1000) : null) ? new Date(new Date(res[0].indate).getTime() + Math.max(Number(res[0].stday), 1) * 24 * 60 * 60 * 1000).toLocaleDateString('en-GB', { day: '2-digit', month: '2-digit', year: '2-digit' }).replace(/\//g, '/') : "-"}</td>
                                        <td className="hide-on-mobile" style={{ wordWrap: "break-word" }}>{res[0].intime ? new Date(`2022-01-01T${res[0].intime}`).toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit', hour12: true }) : "-"}</td>
                                        <td className="hide-on-mobile" style={{ wordWrap: "break-word" }}>{res[0].roomdescription}</td>
                                        <td className="hide-on-mobile" style={{ wordWrap: "break-word" }}>{res[res.length - 1]}</td>
                                        <td className="hide-on-mobile" style={{ wordWrap: "break-word" }}>{res[0].gname}</td>
                                        <td className="hide-on-mobile" style={{ wordWrap: "break-word" }}>{res[0].mobno}</td>
                                        <td className="hide-on-mobile" style={{ wordWrap: "break-word" }}>{res[0].add1}</td>
                                        <td className="f-size-on-mobile" style={{ wordWrap: "break-word" }}>
                                            <Link to={"/Checkin"} onClick={(e) => { cmdEdit(res[0].id, res[res.length - 1]) }} className='btn btn-sm btn-outline-dark'><i class="fa-solid fa-pen-to-square"></i><span className="hide-on-mobile" > Edit</span></Link> &nbsp;  <span className="detail-on-mobile"><br /> <br /></span>
                                            <Link onClick={(e) => { handleOpenModal(res[0].id, res[0].roomdescription, res[0].roomdescriptioncode, res[res.length - 1]) }} className='btn btn-sm btn-danger'> <i class="fa-solid fa-trash"></i> <span className="hide-on-mobile"> Delete</span></Link>&nbsp;
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <Modal isOpen={isModalOpen} onRequestClose={handleCloseModal} contentLabel="Delete Confirmation" className="modal-content" overlayClassName="modal-overlay">
                <div><p>Are you sure you want to delete this <br /> Check-In Room No : {deletemsg}, Status : {checkinstatus} ?</p><div className="button-container"><button className="yes-button" onClick={(e) => { deleteData(deleteid, deletemsg) }}>Yes</button> <button className="no-button" onClick={handleNoClick}>No</button></div></div>
            </Modal>
            <ToastContainer autoClose={1000}></ToastContainer>
            {isLoading && <Loading />}
        </div>
    )
}
export default Checkin
